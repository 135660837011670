import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {Modal} from "../Modal/Modal";
import {FeatureRole, User} from "../../models/user";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import Toggle from "@jetbrains/ring-ui/dist/toggle/toggle";
import Loader from "@jetbrains/ring-ui/dist/loader/loader";
import {ProductDescriptor} from "../../models/product-descriptor";
import {usersActions} from "../../redux/slices/users-slice";
import Checkbox from "@jetbrains/ring-ui/dist/checkbox/checkbox";

interface Props {
    show: boolean;
    user: User;
    onClose(): void;
}

const EDITABLE_ROLES: {role: FeatureRole, label: string, advanced?: boolean}[] = [
    {role: "PROCESSORS_EDITOR", label: "Processors editor"},
    {role: "ADVANCED_EDITOR", label: "Advanced content editor"},
    {role: "FEATURE_TESTER", label: "Features tester"},
    {role: "MAU_TEAM", label: "MAU admin user", advanced: true},
];

export const EditUserModal: React.FC<Props> = (props) => {

    const {show, user, onClose} = props;
    const dispatch = useAppDispatch();
    const products = useAppSelector(state => state.content.products);
    const [selectedProducts, setSelectedProducts] = useState<ProductDescriptor[]>([]);
    const [notificationEnabled, setNotificationEnabled] = useState(false);
    const [selectedFeatures, setSelectedFeatures] = useState<FeatureRole[]>(user.featureRoles);

    useEffect(() => {
        if (products !== null) {
            setSelectedProducts(products.filter(p => user.authorizedProducts.includes(p.value)));
        }
    }, [products]);

    const onProductSwitch = (product: ProductDescriptor, selected: boolean) => {
        if (selected) {
            setSelectedProducts([product, ...selectedProducts]);
        } else {
            setSelectedProducts(selectedProducts.filter(p => p.value !== product.value));
        }
    };

    const onFeatureRoleSwitch = (role: FeatureRole, selected: boolean) => {
        if (selected) {
            setSelectedFeatures([...selectedFeatures, role]);
        } else {
            setSelectedFeatures(selectedFeatures.filter(f => f !== role));
        }
    };

    const save = () => {
        dispatch(usersActions.changeUserProducts({
            email: user.email,
            products: selectedProducts,
            featureRoles: selectedFeatures,
            notifyUser: notificationEnabled,
        }));
        onClose();
    };

    return <Modal
        show={show}
        title={`Edit user ${user.email}`}
        onClose={onClose}
        actions={[{
            fn: save,
            label: "Save",
            primary: true,
        }]}
    >
        <S.Label>Products access:</S.Label>
        <S.ProductsList>
            {products !== null ? products.filter(p => p.value !== "CrossPost").map(product => <div key={product.value} style={{flex: "32%"}}>
                <Toggle defaultChecked={user.authorizedProducts.includes(product.value)} onChange={e => onProductSwitch(product, e.target.checked)}>
                    {product.label}
                </Toggle>
            </div>) : <Loader message="Loading products list..."/>}
        </S.ProductsList>

        <hr/>

        <S.Label>Additional features:</S.Label>
        {EDITABLE_ROLES.map(r => <div key={r.role}>
            <Toggle defaultChecked={selectedFeatures.includes(r.role)} onChange={e => onFeatureRoleSwitch(r.role, e.target.checked)}>
                <span style={{color: r.advanced ? "darkred" : "black"}}>{r.label}</span>
            </Toggle>
        </div>)}

        <hr/>

        <div style={{marginTop: "12px"}}>
            <Checkbox
                label="Send a Slack-notification to the user"
                checked={notificationEnabled}
                onChange={() => setNotificationEnabled(!notificationEnabled)}
            />
        </div>
    </Modal>

};
