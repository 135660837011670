import styled from "styled-components";

export const StyledFooter = styled.footer`
  height: 40px;
  line-height: 40px;
  font-size: 0.9em;
  color: whitesmoke;
  background-color: black;
  width: 100vw;
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  justify-content: flex-end;
  padding: 0 16px;
`;

export const Link = styled.a`
  color: white;
  text-decoration: none;
  transition: color ease-in-out 0.3s;
  &:hover {
    color: #f50057;
  }
  &:not(&:last-child):after {
    content: "|";
    display: inline-block;
    margin-left: 16px;
    color: #777 !important;
  }
`;
