import styled from "styled-components";

export const ModalContent = styled.div`
  text-align: justify;
`;

export const Warning = styled.div`
  font-size: 0.85em;
  padding: 8px;
  margin: 8px 0;
  background-color: coral;
  border-radius: 8px;
`;

export const DatepickerContainer = styled.div`
    text-align: center;
  .react-datepicker__time-list-item--disabled {
    display: none !important;
  }
`;
