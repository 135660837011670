import styled from "styled-components";

export const EditorPageLayout = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const EditorLayout = styled.div`
  display: flex;
  flex-flow: row nowrap;
  height: 100%;
`;

export const ControlsContainer = styled.div<{newUi: boolean}>`
  width: 600px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding: 0 12px;
  ${props => props.newUi ? `
    color: whitesmoke;
    background-color: #222;
    input {
      color: whitesmoke !important;
    }
    div[data-test="ring-input"] > label {
      color: whitesmoke;
      font-size: 0.9em;
      margin-top: 12px;
      &::after {
        content: ":";
      }
    }
  ` : ``}  
`;

export const PreviewContainer = styled.div`
  flex-grow: 1;
  border-left: 1px solid #CCC;
  padding: 20px;
  height: 100%;
  overflow-y: auto;
  
  h2 {
    margin: 0 0 12px;
    font-weight: 300;
  }
`;

export const PreviewSenderInfo = styled.p`
  margin-bottom: 20px;
`;

export const IFrame = styled.iframe`
  display: block;
  width: 100%;
  height: 100vh;
  margin: auto;
  padding: 20px;
  border: none;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  &.mobile {
    width: 390px;
    height: 844px;
    transform-origin: 0 0;
    transform:scale(1);
  }
`;

export const ContentAlreadySentWarning = styled.p`
  background-color: salmon;
  padding: 6px;
  border-radius: 6px;
  margin-bottom: 0;
`;

export const ToggleLabel = styled.label`
  font-size: 0.8em;
  margin-bottom: 16px;
  display: inline-block;
`;

export const PreviewOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const LoaderContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.85);
  padding: 20px 40px;
  width: 300px;
  height: 160px;
  border-radius: 50px;
  top: 20vh;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
  color: white;
  text-align: center;
`;

export const CampaignSelectionButton = styled.button<{newUi: boolean}>`
  display: inline-block;
  border: 1px solid #c5d1db;
  background-color: transparent;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  transition: background-color 400ms ease-in-out;
  margin: 8px 0 8px 8px;

  &:hover {
    border-color: #80c6ff;
    background-color: #f4fbff;
  }
    
  ${props => props.newUi ? `
    background-color: whitesmoke;
    color: black;
    &:hover {
      background-color: #CCC;
    }
  ` : ``}
`;
