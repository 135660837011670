import {ContentTag} from "./content-tag";

export class ContentDescriptor {
    readonly date: Date;
    readonly image: string | null;
    constructor(
        public readonly id: string,
        public readonly title: string,
        date: string,
        public readonly tags: ContentTag[],
        image: string | undefined,
        public readonly sent: boolean,
        public readonly scheduled: boolean,
        public readonly link: string,
        public readonly productNames: string[],
        public readonly author: string,
    ) {
        this.date = new Date(date);
        this.image = image ?? null;
    }
}
