import React from "react";
import {IconType} from "react-icons";

interface Props {
    Type: IconType;
    style?: React.CSSProperties;
}

/**
 * There is something weird with react-icons positioning. This is a wrapper to handle it in one place.
 */
export const Icon: React.FC<Props> = (props) => {

    const {Type, style} = props;

    return <span style={style}>
        <Type style={{transform: "translateY(2px)"}}/>
    </span>;

};
