import styled from "styled-components";

export const Slide = styled.div`
  height: 200px;
  width: 400px;
  margin: auto;
`;

export const FakeLink = styled.span`
  color: #028eff;
  cursor: pointer;
  display: inline-block;
  background-color: lavender;
  font-weight: 600;
  font-size: 0.9em;
  padding: 1px 8px;
  border-radius: 4px;
  line-height: 20px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 16px;
  justify-content: center;
  margin-top: 16px;
`;
