import styled from "styled-components";

export const CampaignInputs = styled.div`
  div[data-test="ring-select"] {
    margin: 4px 0;
  }
`;

export const InfoLabel = styled.p`
  font-size: 0.8em;
  color: #777;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const CampaignName = styled.p`
  margin: 4px 0 0;
  font-weight: bold;
  color: black;
`;

export const CampaignAudienceDescription = styled.span`
  display: block;
  font-size: 0.8em;
  color: #777;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.h2`
  font-weight: 400;
`;
