import {map, Observable} from "rxjs";
import {FeatureRole, User} from "../models/user";
import axios from "axios-observable";
import {CONFIG} from "../config";
import {CurrentUserInfo} from "../models/current-user-info";
import {ProductDescriptor} from "../models/product-descriptor";
import {UserExistenceCheckResponse} from "../models/user-existence-check-response";

export namespace UsersApi {

    export function getAllUsers(): Observable<User[]> {
        console.log("Loading all users");
        return axios.get(`${CONFIG.apiHost}api/users/`, {withCredentials: true}).pipe(
            map(response => (response.data as any[]).map(e => User.parse(e))),
        );
    }

    export function getCurrentUserInfo(): Observable<CurrentUserInfo> {
        console.log("Loading current user info");
        return axios.get(`${CONFIG.apiHost}api/users/me`, {withCredentials: true}).pipe(
            map(response => CurrentUserInfo.parse(response.data)),
        );
    }

    export function createUser(email: string, products: ProductDescriptor[], notifyUser: boolean): Observable<User> {
        console.log("Creating a new user");
        return axios.post(`${CONFIG.apiHost}api/users/`, {
            email,
            authorizedProducts: products.map(p => p.value),
            notifyUser,
        }, {withCredentials: true}).pipe(
            map(response => User.parse(response.data)),
        );
    }

    export function deleteUser(email: string): Observable<any> {
        console.log("Deleting user");
        const request = {email};
        return axios.delete(`${CONFIG.apiHost}api/users/`, {data: request, withCredentials: true});
    }

    export function editUser(email: string, products: ProductDescriptor[], featureRoles: FeatureRole[], notifyUser: boolean): Observable<User> {
        console.log("Changing user's products list");
        return axios.patch(`${CONFIG.apiHost}api/users/`, {
            email,
            authorizedProducts: products.map(p => p.value),
            featureRoles,
            notifyUser,
        }, {withCredentials: true}).pipe(
            map(response => User.parse(response.data)),
        );
    }

    export function checkUserExistence(email: string): Observable<UserExistenceCheckResponse> {
        console.log(`Checking existence of a user with email ${email}`);
        return axios.get(`${CONFIG.apiHost}api/users/check`, {params: {email}, withCredentials: true}).pipe(
            map(response => response.data as UserExistenceCheckResponse),
        );
    }

}
