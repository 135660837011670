import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {configActions} from "../../redux/slices/config-slice";
import Button from "@jetbrains/ring-ui/dist/button/button";
import Toggle from "@jetbrains/ring-ui/dist/toggle/toggle";

export const GeneralConfigSection: React.FC = () => {

    const {sendoutsBlockingEnabled} = useAppSelector(state => state.config);
    const dispatch = useAppDispatch();
    const [newSendoutsBlockingFlagValue, setNewSendoutsBlockingFlagValue] = useState(false);
    const formIsValid = sendoutsBlockingEnabled !== newSendoutsBlockingFlagValue;

    useEffect(() => setNewSendoutsBlockingFlagValue(sendoutsBlockingEnabled ?? false), [sendoutsBlockingEnabled]);

    useEffect(() => {
        dispatch(configActions.loadSendoutsBlockingFlag());
    }, []);

    const save = () => {
        if (formIsValid) {
            if (newSendoutsBlockingFlagValue !== sendoutsBlockingEnabled) {
                dispatch(configActions.setSendoutsBlockingFlag(newSendoutsBlockingFlagValue));
            }
        } else {
            throw Error("An attempt to submit an invalid form");
        }
    };

    const onSendoutsBlockingFlagToggle = () => setNewSendoutsBlockingFlagValue(!newSendoutsBlockingFlagValue);

    return <>
        <div style={{marginTop: "12px"}}>
            <Toggle checked={newSendoutsBlockingFlagValue ?? false} onChange={onSendoutsBlockingFlagToggle}>Block any new and scheduled sendouts from regular users</Toggle>
        </div>

        <S.ButtonsContainer>
            <Button disabled={!formIsValid} onClick={save}>Save changes</Button>
        </S.ButtonsContainer>
    </>;

};
