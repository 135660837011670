import styled from "styled-components";

export const Info = styled.p`
  margin: 3px 0;
  font-size: 0.9em;
  color: #666;
`;

export const SelectorContainer = styled.div`
  margin: 6px 0 12px;
  max-width: 650px;
`;

export const ContentContainer = styled.div`
  max-width: 100%;
  max-height: 60vh;
  overflow: auto;
`;
