export class SendoutIssue {
    constructor(
        public readonly type: "BROKEN_LINK" | "BROKEN_TWITTER_LINK",
        public readonly name: string,
        public readonly description: string | null,
        public readonly severity: "WARNING" | "ERROR",
    ) {
    }

    static parse(data: any): SendoutIssue {
        return new SendoutIssue(
            data.type,
            data.name,
            data.description,
            data.severity,
        );
    }

    copy({
        type = this.type,
        name = this.name,
        description = this.description,
        severity = this.severity,
    }: Partial<SendoutIssue>): SendoutIssue {
        return new SendoutIssue(type, name, description, severity);
    }
}
