import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {Modal, ModalWidth} from "../Modal/Modal";
import {ContentApi} from "../../api/content-api";
import {ContentDebuggingResult, ContentDebuggingStep} from "../../models/content-debugging-result";
import Loader from "@jetbrains/ring-ui/dist/loader/loader";
import Select from "@jetbrains/ring-ui/dist/select/select";
import {Size} from "@jetbrains/ring-ui/dist/input/input";
import {SendoutsApi} from "../../api/sendouts-api";

interface Props {
    show: boolean;
    sendoutId: string;
    onClose(): void;
}

export const ContentDebuggerModal: React.FC<Props> = (props) => {

    const {show, sendoutId, onClose} = props;
    const [debuggingResult, setDebuggingResult] = useState<null | ContentDebuggingResult>(null);
    const [selectedStep, setSelectedStep] = useState<null | ContentDebuggingStep>(null);

    useEffect(() => {
        if (show) {
            setDebuggingResult(null);
            setSelectedStep(null);
            SendoutsApi.debugContent(sendoutId).subscribe(setDebuggingResult);
        }
    }, [show]);

    return <Modal show={show} title="Content debugger" onClose={onClose} modalWidth={ModalWidth.WIDE}>
        <div>
            <S.Info>This debugger allows you to check all content modifications performed by JetBlog step-by-step.</S.Info>
            <S.Info>Just choose a step and you'll see an intermediate content state after that step execution.</S.Info>
            {debuggingResult && <div>
                <S.SelectorContainer>
                    <Select
                        label="Step"
                        data={debuggingResult.steps.map((step) => ({
                            label: step.name,
                            value: step,
                            key: step.name,
                        }))}
                        size={Size.FULL}
                        onChange={(e: any) => setSelectedStep(e.value)}
                    />
                </S.SelectorContainer>
                {selectedStep && <>
                    <S.ContentContainer dangerouslySetInnerHTML={{__html: selectedStep.intermediateContent["Email Content"]}} />
                </>}
            </div> || <Loader title="Loading debug data..."/>}
        </div>
    </Modal>;

};
