import styled from "styled-components";

export const ConfigPageLayout = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  width: 100%;
  height: 100%;
  padding: 8px;
`;

export const Sidebar = styled.div`
  width: 200px;
  display: flex;
  flex-flow: column nowrap;
  border-right: 1px solid lightgray;
`;

export const Content = styled.div`
  width: 100%;
  overflow-y: auto;
`;
