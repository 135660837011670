import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {Modal} from "../Modal/Modal";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import {StatisticsService} from "../../utils/statistics-service";
import Button from "@jetbrains/ring-ui/dist/button/button";
import {useAppSelector} from "../../redux/store";
import {getDaysBetweenDates} from "../../utils/utils";

const CURRENT_DIALOG_ID = "3";
const LOCALSTORAGE_KEY = "lastSeenMotd";
const START_DATE = new Date("2023-12-20T12:00:00.000+0000");
const MAX_MOTD_AGE_DAYS = 45;

export const MotdDialog: React.FC = () => {

    const currentUser = useAppSelector(state => state.users.currentUserInfo);
    const [show, setShow] = useState(false);

    useEffect(() => {
        const shouldBeShown = currentUser !== null
            && currentUser.createdAt < START_DATE
            && (new Date() >= START_DATE)
            && getDaysBetweenDates(START_DATE, new Date()) <= MAX_MOTD_AGE_DAYS
            && (!localStorage.getItem(LOCALSTORAGE_KEY) || localStorage.getItem(LOCALSTORAGE_KEY) !== CURRENT_DIALOG_ID);
        setShow(shouldBeShown);
    }, [currentUser]);

    function onClose() {
        setShow(false);
        localStorage.setItem(LOCALSTORAGE_KEY, CURRENT_DIALOG_ID);
    }

    function openDocs() {
        StatisticsService.openLinkAndAddEntry("https://jetbrains.team/p/marketing/documents/Email-Marketing-and-Marketing-Automation/a/02a---JetBlog-User-manual-for-the-sendout-interface");
    }

    function openReleaseNotes() {
        StatisticsService.openLinkAndAddEntry("https://youtrack.jetbrains.com/articles/MAU-A-10/Release-Notes-December-23");
    }

    return <Modal
        show={show}
        title={<span style={{color: "whitesmoke", textAlign: "center", display: "block", fontWeight: 300}}>Release Notes - December '23</span>}
        onClose={onClose}
        background={"linear-gradient(to right, #fc5c7d, #6a82fb)"}
        closeButtonInFooter={false}
    >
        <div style={{color: "whitesmoke", lineHeight: "30px"}}>
            <Carousel
                autoPlay={true}
                showThumbs={false}
                dynamicHeight={false}
                infiniteLoop={true}
                showStatus={false}
                showIndicators={false}
                interval={8000}
            >
                <S.Slide>
                    <h3>🥳 Updated Feed and Sendouts pages</h3>
                    <ul>
                        <li>Enhancements in cross-posting workflow</li>
                        <li>Visual indicator for stopped sendouts</li>
                    </ul>
                </S.Slide>
                <S.Slide>
                    <h3>🎛️ Editor changes</h3>
                    <ul>
                        <li>Support for MP4 videos</li>
                        <li>Optimized layout for displaying Tweets</li>
                        <li>Several updates to email design</li>
                    </ul>
                </S.Slide>
                <S.Slide>
                    <h3>🚀 Other improvements</h3>
                    <ul>
                        <li>Improved Slack alerts for new users</li>
                        <li>The User <S.FakeLink onClick={openDocs}>manual</S.FakeLink> has been revised to the most recent version</li>
                    </ul>
                </S.Slide>
            </Carousel>
            <S.ButtonsContainer>
                <Button onClick={openReleaseNotes} primary>Find out more</Button>
            </S.ButtonsContainer>
        </div>
    </Modal>;

};
