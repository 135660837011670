import styled from "styled-components";

export const ActionsList = styled.div`
  height: 0;
  overflow: hidden;
  transition: height 0.3s cubic-bezier(.25,.8,.25,1);
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  &::before {
    content: "";
    flex-grow: 1;
  }
`;

export const Post = styled.div`
  margin: 10px 0;
  padding: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  
  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    ${ActionsList} {
      height: 28px;
    }
  }
  
  .post-image {
    height: 60px;
    width: 128px;
    flex-shrink: 0;
  }
`;

export const PostHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 8px;
  gap: 16px;
`;

export const TagsList = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 4px;
`;

export const SentLabel = styled.span`
  font-size: 0.8em;
  background-color: palegoldenrod;
  display: block;
  padding: 3px 6px;
  border-radius: 3px;
`;

export const PostLink = styled.a`
  color: black;
  text-decoration: none;
`;

export const ProductLabel = styled.span<{crossposted: boolean}>`
  font-size: 0.8em;
  display: inline-block;
  padding: 4px 8px;
  margin: 0 8px;
  border-radius: 4px;
  background-color: ${props => props.crossposted ? "#7fb6de" : "#eaf6ff"};
`;

export const PostMetadata = styled.span`
  display: block;
  text-align: end;
  color: #777;
  font-size: 0.8em;
  flex-shrink: 0;
`;
