import React from "react";
import * as S from "./styles";
import {useAppSelector} from "../../redux/store";

interface Props {
    newUi: boolean;
}

export const StatusPanel: React.FC<Props> = (props) => {

    const {overallStatus} = useAppSelector(state => state.sendouts);
    const blockingForRegularUsers = useAppSelector(state => state.config.sendoutsBlockingEnabled) ?? false;
    const isMauUser = useAppSelector(state => state.users.currentUserInfo?.isAdmin ?? false);

    return <div style={props.newUi ? {padding: "4px 0 0 8px"} : {}}>
        {overallStatus?.active === false && overallStatus.pausedUntil !== null && <S.PauseWarning>
            ⚠️ New content processing is paused now. All upcoming sendouts will be scheduled to be sent after {overallStatus.pausedUntil.toLocaleString("ru-RU")}
        </S.PauseWarning>}
        {blockingForRegularUsers && <S.PauseWarning>
            ⚠️ Manual blocking of new sendouts{isMauUser ? " (created by regular users)" : ""} is enabled, new sendouts will not be scheduled. {isMauUser ? "You can disable it on the Config tab" : ""}
        </S.PauseWarning>}
    </div>;

};
