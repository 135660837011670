import React, {useState} from "react";
import * as S from "./styles";
import {Icon} from "../Icon/Icon";
import {IconType} from "react-icons";
import Button from "@jetbrains/ring-ui/dist/button/button";

interface DockOption {
    title: string;
    description: string;
    icon: IconType;
    submenu: SubOption[];
    popoverLeftOffset: number;
    fn?: Function;
    disabled?: boolean;
}
type SubOption = {label: string, fn: Function, disabled?: boolean};

interface Props {
    options: DockOption[];
}

interface PopoverMenuProps {
    options: SubOption[];
    leftOffset: number;
}

const PopoverMenu: React.FC<PopoverMenuProps> = (props) => {

    const {options, leftOffset} = props;
    const height = 18 + options.length * 28;

    return <>
        <S.Popover leftOffset={leftOffset} height={height}>
            {options.map(o => <Button text key={o.label} onClick={() => o.fn()} disabled={o.disabled} style={{display: "block"}}>
                {o.label}
            </Button>)}
        </S.Popover>
        <S.Arrow/>
    </>;

};


export const ButtonsDock: React.FC<Props> = (props) => {

    const options = props.options;
    const [selectedSubmenu, setSelectedSubmenu] = useState<SubOption[] | null>(null);

    const toggle = (button: DockOption) => {
        if (selectedSubmenu === button.submenu) {
            setSelectedSubmenu(null);
        } else {
            setSelectedSubmenu(button.submenu);
        }
    };

    return <S.Dock>
        {options.map(o => <S.ButtonContainer key={o.title}>
            <S.MultiButton onClick={() => o.fn ? o.fn() : toggle(o)} disabled={o.disabled}>
                <S.ButtonIconSpan>
                    <Icon Type={o.icon}/>
                </S.ButtonIconSpan>
                <div style={{flex: "1 0"}}>
                    <span className="dock-btn-title">{o.title}</span>
                    <span className="dock-btn-desc">{o.description}</span>
                </div>
            </S.MultiButton>
            {selectedSubmenu === o.submenu && <PopoverMenu options={o.submenu} leftOffset={o.popoverLeftOffset}/>}
        </S.ButtonContainer>)}
    </S.Dock>;

};
