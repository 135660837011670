import styled from "styled-components";

export const Dock = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 16px;
`;

export const ButtonContainer = styled.div`
  position: relative;
  width: 164px;
  flex: 1 1 auto;
`;

export const MultiButton = styled.button`
  height: 48px;
  width: 100%;
  border-radius: 24px;
  cursor: pointer;
  background: rgb(17,113,190);
  background: linear-gradient(90deg, rgba(17,113,190,1) 0%, rgba(26,148,246,1) 100%);
  border: none;
  color: white;
  transition: all ease-in-out 300ms;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 12px;
  &[disabled] {
    background: #95C1ED;
    cursor: auto;
  }
  &:not([disabled]):hover, &:not([disabled]):focus {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
  .dock-btn-title {
    font-size: 1.2em;
    display: block;
    font-weight: 600;
  }
  .dock-btn-desc {
    font-size: 0.9em;
    display: block;
    @media (max-width: 1000px) {
      display: none;
    }
  }
`;

export const ButtonIconSpan = styled.span`
  display: block;
  font-size: 1.6em;
`;

export const Popover = styled.div<{leftOffset: number, height: number}>`
  position: absolute;
  top: -${props => (props.height + 6)}px;
  left: min(0, ${props => props.leftOffset}px);
  height: ${props => props.height}px;
  min-width: 200px;
  background-color: black;
  border-radius: 8px;
  padding: 8px;
  z-index: 102;
  white-space: nowrap;
`;

export const Arrow = styled.div`
  height: 12px;
  width: 12px;
  background-color: black;
  transform: rotate(45deg);
  position: absolute;
  top: -14px;
  z-index: 101;
  left: 40px;
`;
