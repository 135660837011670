import {combineEpics, Epic, ofType} from "redux-observable";
import {
    AddCampaignActionPayload,
    configActions, DeleteCampaignActionPayload,
    UpdateCampaignActionPayload,
} from "../slices/config-slice";
import {catchError, from, map, of, switchMap, tap} from "rxjs";
import {ConfigApi} from "../../api/config-api";
import {miscActions} from "../slices/misc-slice";
import {PayloadAction} from "@reduxjs/toolkit";
import {CONFIG} from "../../config";
import alertService from "@jetbrains/ring-ui/dist/alert-service/alert-service";

const onInit$: Epic = (action$) => action$.pipe(
    ofType(miscActions.onInit),
    switchMap(() => from([
        configActions.loadMarketoConfig(),
        configActions.loadSendoutsBlockingFlag(),
    ])),
);

const loadSendoutsBlockingFlag$: Epic = (action$) => action$.pipe(
    ofType(configActions.loadSendoutsBlockingFlag),
    switchMap(() => ConfigApi.getSendoutsBlockingFlag().pipe(
        map(enabled => configActions.onSendoutsBlockingFlagLoaded(enabled)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const setSendoutsBlockingFlag$: Epic = (action$) => action$.pipe(
    ofType(configActions.setSendoutsBlockingFlag),
    switchMap((action: PayloadAction<boolean>) => ConfigApi.setSendoutsBlockingFlag(action.payload).pipe(
        map(enabled => configActions.onSendoutsBlockingFlagUpdated(enabled)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const loadMarketoConfig$: Epic = (action$) => action$.pipe(
    ofType(configActions.loadMarketoConfig),
    switchMap(() => ConfigApi.getConfig().pipe(
        map(configActions.onMarketoConfigLoaded),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const updateCampaign$: Epic = (action$) => action$.pipe(
    ofType(configActions.updateCampaign),
    switchMap((action: PayloadAction<UpdateCampaignActionPayload>) => ConfigApi.updateCampaign(action.payload.campaignId, action.payload).pipe(
        map(configActions.onCampaignUpdated),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onCampaignUpdated$: Epic = (action$) => action$.pipe(
    ofType(configActions.onCampaignUpdated),
    tap(() => alertService.successMessage("Campaign was updated", CONFIG.defaultAlertsTimeoutMs)),
    map(configActions.loadMarketoConfig),
);

const addCampaign$: Epic = (action$) => action$.pipe(
    ofType(configActions.addCampaign),
    switchMap((action: PayloadAction<AddCampaignActionPayload>) => ConfigApi.addCampaign(action.payload).pipe(
        map(configActions.onCampaignAdded),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onCampaignAdded$: Epic = (action$) => action$.pipe(
    ofType(configActions.onCampaignAdded),
    map(configActions.loadMarketoConfig),
    tap(() => alertService.successMessage("Campaign created", CONFIG.defaultAlertsTimeoutMs)),
);

const deleteCampaign$: Epic = (action$) => action$.pipe(
    ofType(configActions.deleteCampaign),
    switchMap((action: PayloadAction<DeleteCampaignActionPayload>) => ConfigApi.deleteCampaign(action.payload.campaignId).pipe(
        map(configActions.onCampaignDeleted),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onCampaignDeleted$: Epic = (action$) => action$.pipe(
    ofType(configActions.onCampaignDeleted),
    map(configActions.loadMarketoConfig),
    tap(() => alertService.successMessage("Campaign deleted", CONFIG.defaultAlertsTimeoutMs)),
);

const loadKnownProcessors$: Epic = (action$) => action$.pipe(
    ofType(configActions.loadKnownProcessors),
    switchMap(() => ConfigApi.getAvailableProcessors().pipe(
        map(processors => configActions.onKnownProcessorsLoaded(processors)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

export const configEpics = combineEpics(
    onInit$,
    loadSendoutsBlockingFlag$,
    setSendoutsBlockingFlag$,
    loadMarketoConfig$,
    updateCampaign$,
    onCampaignUpdated$,
    addCampaign$,
    onCampaignAdded$,
    deleteCampaign$,
    onCampaignDeleted$,
    loadKnownProcessors$,
);
