import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {Modal, ModalWidth} from "../Modal/Modal";
import CodeMirror from '@uiw/react-codemirror';
import { html } from '@codemirror/lang-html';
import pretty from "pretty";
import {useAppDispatch} from "../../redux/store";
import {editorActions} from "../../redux/slices/editor-slice";
import {sublime} from '@uiw/codemirror-theme-sublime';

interface Props {
    show: boolean;
    sendoutId: string;
    initialHtml: string;
    onClose(edited: boolean): void;
    contentType: "raw" | "processed";
}

const rawContentInfo = <div>
    <p>
        Raw content – is a content retrieved from the blog. Edit it if you found some issues in the content itself
        (like typos, unused elements, etc.).
    </p>
    <div style={{backgroundColor: "yellow", width: "fit-content", margin: "0 0 10px"}}>
        Warning: any changes in <u>raw</u> content will override changes in <u>processed</u> content (in case you
        already made them).
    </div>
</div>;

const processedContentInfo = <div>
    <p>
        Processed content – is a raw content after our pre- and post-processors did their work. It also includes
        injected CSS-rules specific for different email clients. Edit this content if you think that the problem
        you're facing is on our side. Be really careful! Don't touch any styles if you're not sure what they are
        designed for.
    </p>
</div>;

export const EditContentModal: React.FC<Props> = React.memo((props) => {

    const {show, sendoutId, initialHtml, onClose, contentType} = props;
    const dispatch = useAppDispatch();
    const [content, setContent] = useState(pretty(initialHtml));

    useEffect(() => {
        setContent(pretty(initialHtml));
    }, [initialHtml]);

    const save = () => {
        if (contentType === "raw") {
            dispatch(editorActions.patchSendout({sendoutId, request: {rawHtml: content}}));
        } else {
            dispatch(editorActions.patchSendout({sendoutId, request: {processedHtml: content}}));
        }
        onClose(true);
    };

    return <Modal
        show={show}
        title={contentType === "raw" ? "Raw content editor" : "Processed content editor"}
        onClose={() => onClose(false)}
        modalWidth={ModalWidth.WIDE}
        actions={[{
            fn: save,
            label: "Save changes",
            primary: true,
        }]}
    >

        <div>
            {contentType === "raw" ? rawContentInfo : processedContentInfo}
            <S.EditorContainer>
                <CodeMirror
                    value={content}
                    height="100%"
                    extensions={[html({  })]}
                    onChange={setContent}
                    theme={sublime}
                />
            </S.EditorContainer>
        </div>

    </Modal>

});
