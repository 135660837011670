import {combineEpics, Epic, ofType} from "redux-observable";
import {
    DeleteUserActionPayload,
    EditUserActionPayload,
    NewUserActionPayload,
    usersActions
} from "../slices/users-slice";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {UsersApi} from "../../api/users-api";
import {miscActions} from "../slices/misc-slice";
import {PayloadAction} from "@reduxjs/toolkit";
import {CONFIG} from "../../config";
import alertService from "@jetbrains/ring-ui/dist/alert-service/alert-service";

const loadUsers$: Epic = (action$) => action$.pipe(
    ofType(usersActions.loadUsers),
    switchMap(() => UsersApi.getAllUsers().pipe(
        map(users => usersActions.onUsersLoaded(users)),
        catchError((response) => of(miscActions.onError(response))),
    ))
);

const loadCurrentUserInfo$: Epic = (action$) => action$.pipe(
    ofType(usersActions.loadCurrentUserInfo),
    switchMap(() => UsersApi.getCurrentUserInfo().pipe(
        map(userInfo => usersActions.onCurrentUserInfoLoaded(userInfo)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const createUser$: Epic = (action$) => action$.pipe(
    ofType(usersActions.createNewUser),
    switchMap((action: PayloadAction<NewUserActionPayload>) => UsersApi.createUser(action.payload.email, action.payload.products, action.payload.notifyUser).pipe(
        map(user => usersActions.onUserCreated(user)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onUserCreated$: Epic = (action$) => action$.pipe(
    ofType(usersActions.onUserCreated),
    map(usersActions.loadUsers),
    tap(() => {
        alertService.successMessage("New user has been created", CONFIG.defaultAlertsTimeoutMs);
    }),
);

const deleteUser$: Epic = (action$) => action$.pipe(
    ofType(usersActions.deleteUser),
    switchMap((action: PayloadAction<DeleteUserActionPayload>) => UsersApi.deleteUser(action.payload.email).pipe(
        map(() => usersActions.onUserDeleted()),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onUserDeleted$: Epic = (action$) => action$.pipe(
    ofType(usersActions.onUserDeleted),
    map(usersActions.loadUsers),
    tap(() => {
        alertService.successMessage("User has been deleted", CONFIG.defaultAlertsTimeoutMs);
    }),
);

const changeUserProducts$: Epic = (action$) => action$.pipe(
    ofType(usersActions.changeUserProducts),
    switchMap((action: PayloadAction<EditUserActionPayload>) => UsersApi.editUser(
        action.payload.email,
        action.payload.products,
        action.payload.featureRoles,
        action.payload.notifyUser,
    ).pipe(
        map(user => usersActions.onUserProductsChanged(user)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onUserProductsChanged$: Epic = (action$) => action$.pipe(
    ofType(usersActions.onUserProductsChanged),
    map(usersActions.loadUsers),
    tap(() => {
        alertService.successMessage("Updated user's products list has been saved", CONFIG.defaultAlertsTimeoutMs);
    }),
);

export const usersEpics = combineEpics(
    loadUsers$,
    loadCurrentUserInfo$,
    createUser$,
    onUserCreated$,
    deleteUser$,
    onUserDeleted$,
    changeUserProducts$,
    onUserProductsChanged$,
);
