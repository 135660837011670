import styled from "styled-components";

export const Label = styled.span`
  display: block;
  position: absolute;
  right: 4px;
  top: 14px;
  background-color: red;
  font-size: 0.8em;
  line-height: calc(1.4em - 8px);
  height: 1.4em;
  padding: 4px;
  border-radius: 4px;
  color: white;
`;