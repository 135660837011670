import styled from "styled-components";

export const FiltersPanel = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
  align-items: center;
`;

export const FilterBlock = styled.div`
  margin-bottom: 10px;
  p {
    margin-bottom: 4px;
  }
`;

export const FilterLabel = styled.span`
  font-size: 0.8em;
  color: #777;
  margin-right: 10px;
`;
