import React from "react";
import * as S from "./styles";
import defaultImg from "../PostsList/placeholder.png";
import {ContentDescriptor} from "../../models/content-descriptor";
import {Icon} from "../Icon/Icon";
import {MdDelete, MdOutlineWarningAmber} from "react-icons/md";
import {useAppSelector} from "../../redux/store";
import {ProductDescriptor} from "../../models/product-descriptor";
import Tooltip from "@jetbrains/ring-ui/dist/tooltip/tooltip";

interface Props {
    posts: ContentDescriptor[];
    onCreate(post: ContentDescriptor): void;
    onDelete(post: ContentDescriptor): void;
    showProductLabel: boolean;
}

export const PostsListV2: React.FC<Props> = (props) => {

    const {posts, onCreate, onDelete, showProductLabel} = props;

    const knownProducts = useAppSelector(state => state.content.products);

    return <S.List>
        {posts.map((post) => <S.Card key={post.id}>
            <a href={post.link} target="_blank">
                <S.Image src={post.image ?? defaultImg} grayscale={post.scheduled || post.sent}/>
            </a>
            {(showProductLabel || post.productNames.length > 1) && <S.ProductTag>{post.productNames.map(p => resolveProductLabel(p, knownProducts)).join(" | ")}</S.ProductTag>}
            {post.sent && !post.scheduled && <S.Line type="sent"><Icon Type={MdOutlineWarningAmber}/>SENT</S.Line>}
            {post.scheduled && <S.Line type="scheduled"><Icon Type={MdOutlineWarningAmber}/>SCHEDULED</S.Line>}
            <S.DataContainer>
                <a href={post.link} target="_blank" style={{color: "black", textDecoration: "none"}}>
                    <S.Title>{post.title}</S.Title>
                </a>
                <S.Meta>
                    {post.date.toLocaleString("ru-RU")} by {post.author}
                </S.Meta>
            </S.DataContainer>
            <S.ButtonsContainer>
                <S.Button onClick={() => onCreate(post)}>
                    Create a new sendout
                </S.Button>
                <Tooltip title="Delete this blogpost">
                    <S.IconButton onClick={() => onDelete(post)} mainColor="#ff6767" hoverColor="#F64C49">
                        <Icon Type={MdDelete}/>
                    </S.IconButton>
                </Tooltip>
            </S.ButtonsContainer>
        </S.Card>)}
    </S.List>;

};

function resolveProductLabel(productName: string, knownProducts: ProductDescriptor[] | null): string {
    return knownProducts?.find(p => p.value === productName)?.label ?? productName;
}
