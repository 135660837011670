import styled from "styled-components";

export const ContentContainer = styled.div<{hoveredId: string | null, selectedId: string | null}>`
  border: 2px solid #777;
  padding: 16px;
  border-radius: 16px;
  overflow-y: auto;
  cursor: pointer;
  max-height: 70vh;
  *[tmp_selector="${props => props.hoveredId ?? "-1"}"] {
    border-bottom: 4px dashed orangered;
    padding-bottom: 10px;
  }
  *[tmp_selector="${props => props.selectedId ?? "-1"}"] {
    & ~ * {
      color: lightgray !important;
      opacity: 50%;
    }
  }
`;

export const Warning = styled.p`
  background-color: orange;
  padding: 6px;
  border-radius: 6px;
  margin: 8px 0;
`;
