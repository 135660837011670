import {combineEpics, Epic, ofType} from "redux-observable";
import {miscActions} from "../slices/misc-slice";
import {ignoreElements, tap} from "rxjs";
import {PayloadAction} from "@reduxjs/toolkit";
import {CONFIG} from "../../config";
import alertService from "@jetbrains/ring-ui/dist/alert-service/alert-service";

const onError$: Epic = (action$) => action$.pipe(
    ofType(miscActions.onError),
    tap((action: PayloadAction<any>) => {
        const code = action.payload.response?.status;
        let message: string | null = action.payload.response?.data?.message ?? null;
        if (message && message.length > 100) {
            message = message.substring(0, 100) + "...";
        }
        console.warn("Got error:");
        console.warn(action.payload);
        if (code === 401) {
            const redirectUrl = `${CONFIG.apiHost}oauth2/authorization/google`;
            window.location.assign(redirectUrl);
        } else if (typeof code === "number") {
            alertService.error(`API call error has occurred (${code})${message ? `: ${message}` : ""}`);
        } else {
            alertService.error("Wow, we got an error of unknown type =/");
        }
    }),
    ignoreElements(),
);

export const miscEpics = combineEpics(
    onError$,
);
