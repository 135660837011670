import React, {useMemo, useState} from "react";
import * as S from "./styles";
import {Sendout} from "../../models/sendout";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {editorActions} from "../../redux/slices/editor-slice";
import {Modal, ModalWidth} from "../Modal/Modal";

interface Props {
    sendout: Sendout;
    show: boolean;
    onClose(): void;
}

export const ReadMoreCustomizationModal: React.FC<Props> = (props) => {

    const {sendout, show, onClose} = props;
    const dispatch = useAppDispatch();
    const user = useAppSelector(state => state.users.currentUserInfo);
    const postId = useMemo(() => getPostId(sendout.contentId), [sendout.contentId]);
    const markedDocument = useMemo(() => markupHtmlString(sendout.rawBody), [sendout.rawBody]);
    const initialPosition = useMemo(() => getCurrentReadMorePosition(markedDocument, postId), [sendout]);
    const [hoveredId, setHoveredId] = useState<string | null>(initialPosition);
    const [selectedId, setSelectedId] = useState<string | null>(initialPosition);

    const processedContentEditorIsAvailable = user?.isAdmin || user?.featureRoles.includes("ADVANCED_EDITOR");

    function onElementClick(event: React.MouseEvent<HTMLDivElement>) {
        const selectedElement = getMarketParent(event.target as Element);
        if (selectedElement !== null) {
            setSelectedId(selectedElement.getAttribute(injectedAttribute));
        }
    }

    function onElementHover(event: React.MouseEvent) {
        const hoveredElement = getMarketParent(event.target as Element);
        if (hoveredElement !== null) {
            setHoveredId(hoveredElement.getAttribute(injectedAttribute));
        }
    }

    function onModalClose() {
        onClose();
    }

    function onConfirm() {
        if (selectedId === null) {
            throw Error();
        }
        const result = removeMarkup(
            replaceReadMorePosition(markedDocument, postId, selectedId)
        );
        dispatch(editorActions.patchSendout({sendoutId: sendout.id, request: {rawHtml: result}}));
        onModalClose();
    }

    function remove() {
        const result = removeReadMore(markedDocument, postId);
        dispatch(editorActions.patchSendout({sendoutId: sendout.id, request: {rawHtml: result}}));
        onModalClose();
    }

    return <Modal
        show={show}
        onClose={onModalClose}
        title="Edit 'Read More' button position"
        modalWidth={ModalWidth.WIDE}
        actions={[
            {
                label: "Save",
                disabled: selectedId === null,
                primary: true,
                fn() {
                    onConfirm();
                }
            },
            {
                label: "Remove",
                disabled: initialPosition === null,
                danger: true,
                fn() {
                    remove();
                },
            }
        ]}
    >

        {processedContentEditorIsAvailable && <S.Warning>⚠️ Please note that editing the position of this button will erase all the changes you made via 'generated content editor'.</S.Warning>}
        <S.ContentContainer hoveredId={hoveredId} selectedId={selectedId} onClick={onElementClick} onMouseOver={onElementHover} dangerouslySetInnerHTML={{__html: markedDocument}}/>

    </Modal>;

};

const injectedAttribute = "tmp_selector";

function markupHtmlString(html: string): string {
    const document = htmlStringToDocument(html);
    let counter = 0;
    Array.from(document.body.children).forEach(node => {
        node.setAttribute(injectedAttribute, (counter++).toString());
    });
    return documentToString(document);
}

function removeMarkup(html: string): string {
    const document = htmlStringToDocument(html);
    const nodes = document.querySelectorAll(`[${injectedAttribute}]`);
    nodes.forEach(node => {
        node.removeAttribute(injectedAttribute);
    });
    return documentToString(document);
}

function htmlStringToDocument(html: string): Document {
    const parser = new DOMParser();
    return parser.parseFromString(html, "text/html");
}

function documentToString(document: Document): string {
    return document.documentElement.outerHTML;
}

function getMarketParent(node: Element): Element | null {
    let currentNode = node;
    while (currentNode.tagName !== "BODY" && !currentNode.hasAttribute(injectedAttribute)) {
        currentNode = currentNode.parentElement!;
    }
    return currentNode.hasAttribute(injectedAttribute) ? currentNode : null;
}

function getCurrentReadMorePosition(html: string, postId: string): string | null {
    const document = htmlStringToDocument(html);
    const span = document.querySelector(`span#more-${postId}`);
    return span?.previousElementSibling?.getAttribute(injectedAttribute) ?? null;
}

function replaceReadMorePosition(html: string, postId: string, precedingElementId: string): string {
    const document = htmlStringToDocument(html);
    const spanId = `more-${postId}`;
    let span = document.querySelector(`span#${spanId}`);
    if (span != null) {
        span.remove();
    } else {
        span = document.createElement("span");
        span.id = spanId;
    }
    const targetElement = document.querySelector(`[${injectedAttribute}="${precedingElementId}"]`)!;
    targetElement.parentElement!.insertBefore(span, targetElement.nextSibling);
    return documentToString(document);
}

function removeReadMore(html: string, postId: string): string {
    const document = htmlStringToDocument(html);
    const spanId = `more-${postId}`;
    document.querySelector(`span#${spanId}`)?.remove();
    return documentToString(document);
}

function getPostId(contentId: string): string {
    const url = new URL(contentId);
    const postId = url.searchParams.get("p");
    if (postId === null) {
        throw new Error("Unknown post ID");
    }
    return postId;
}
