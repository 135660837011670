export class Status {
    public readonly pausedUntil: Date | null;
    constructor(
        public readonly active: boolean,
        pausedUntil?: string | null,
    ) {
        if (pausedUntil) {
            this.pausedUntil = new Date(pausedUntil);
        } else {
            this.pausedUntil = null;
        }
    }

    static parse(obj: any): Status {
        return new Status(obj.active, obj.pausedUntil);
    }
}
