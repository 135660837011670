import {SendoutIssue} from "./sendout-issue";

export type SendoutState = "DRAFT" | "SENT" | "FAILED" | "SCHEDULED" | "CANCELLED" | "BLOCKED" | "ABORTED";
export type SendoutSource = "UI" | "AUTO_TRIGGERED";

export class Sendout {
    readonly createdAt: Date;
    readonly image: string | null;
    readonly creatorEmail: string | null;
    readonly scheduledFor: Date | null;
    readonly sentAt: Date | null;
    readonly targetCampaignId: number;
    readonly errorDescription: string | null;
    readonly processorsConfig: SendoutProcessorsConfig | null;
    readonly triggeredBy: string | null;
    constructor(
        public readonly id: string,
        public readonly contentId: string,
        creatorEmail: string | undefined,
        public readonly creatorName: string,
        createdAt: string,
        public readonly state: SendoutState,
        public readonly source: SendoutSource,
        public readonly title: string,
        image: string | undefined,
        public readonly rawBody: string,
        public readonly processedBody: string,
        scheduledFor: string | undefined,
        sentAt: string | undefined,
        targetCampaignId: number,
        public readonly product: string,
        public readonly emailPreheader: string,
        public readonly issues: SendoutIssue[],
        errorDescription: string | undefined,
        processorsConfig: SendoutProcessorsConfig | null | undefined,
        triggeredBy: string | null | undefined,
        public readonly forcedFeaturedImage: boolean,
    ) {
        this.createdAt = new Date(createdAt);
        this.image = image ?? null;
        this.creatorEmail = creatorEmail ?? null;
        this.scheduledFor = scheduledFor ? new Date(scheduledFor) : null;
        this.sentAt = sentAt ? new Date(sentAt) : null;
        this.targetCampaignId = targetCampaignId;
        this.errorDescription = errorDescription ?? null;
        this.processorsConfig = processorsConfig ?? null;
        this.triggeredBy = triggeredBy ?? null;
    }

    static parse(data: any): Sendout {
        const issues = data.issues?.map((i: any) => new SendoutIssue(
            i.type,
            i.name,
            i.description,
            i.severity,
        )) ?? [];

        return new Sendout(
            data.id,
            data.contentId,
            data.creatorEmail,
            data.creatorName,
            data.createdAt,
            data.state,
            data.source,
            data.title,
            data.image,
            data.rawBody,
            data.processedBody,
            data.scheduledFor,
            data.sentAt,
            data.targetCampaignId,
            data.product,
            data.emailPreheader,
            issues,
            data.errorDescription,
            data.processorsConfig,
            data.triggeredBy,
            data.forcedFeaturedImage,
        );
    }
}

export interface SendoutProcessorsConfig {
    preprocessors?: string[] | null;
    postprocessors?: string[] | null;
}
