import React, {useCallback, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import Input, {Size} from "@jetbrains/ring-ui/dist/input/input";
import Button from "@jetbrains/ring-ui/dist/button/button";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {sendoutsActions} from "../../redux/slices/sendouts-slice";
import {CampaignSelectorDialog} from "../CampaignSelectorDialog/CampaignSelectorDialog";
import {editorActions} from "../../redux/slices/editor-slice";
import {SendoutsApi} from "../../api/sendouts-api";
import * as S from "./styles";
import {EditContentModal} from "../EditContentModal/EditContentModal";
import confirm from "@jetbrains/ring-ui/dist/confirm-service/confirm-service";
import {GetEmailSampleModal} from "../GetEmailSampleModal/GetEmailSampleModal";
import {Icon} from "../Icon/Icon";
import {FaCheck, FaEdit, FaSave} from "react-icons/fa";
import {RiErrorWarningLine, RiMailSendFill} from "react-icons/ri";
import {MarketoCampaign} from "../../models/marketo-config";
import {ConfigApi} from "../../api/config-api";
import {SendoutIssuesList} from "../SendoutIssuesList/SendoutIssuesList";
import {ContentApi} from "../../api/content-api";
import {ContentStatus} from "../../models/content-status";
import {
    changeAuthorNameVisibility,
    checkIfAuthorNameIsDisplayed,
    generatePreviewUrl, getCurrentAuthorName, setAuthorNameInContent,
    unescapeString
} from "../../utils/utils";
import {ContentDebuggerModal} from "../ContentDebuggerModal/ContentDebuggerModal";
import {ButtonsDock} from "../ButtonsDock/ButtonsDock";
import {SendoutProcessorsEditorDialog} from "../SendoutProcessorsEditorDialog/SendoutProcessorsEditorDialog";
import Toggle from "@jetbrains/ring-ui/dist/toggle/toggle";
import Loader from "@jetbrains/ring-ui/dist/loader/loader";
import {CampaignSelectionButton} from "./styles";
import {ScheduleCampaignModal} from "../ScheduleCampaignModal/ScheduleCampaignModal";
import {ReadMoreCustomizationModal} from "../ReadMoreCustomizationModal/ReadMoreCustomizationModal";
import {MultiButton} from "../ButtonsDock/styles";

export const EditorPage: React.FC = () => {

    const urlParams = useParams();
    const sendoutIdFromUrl: string = urlParams.sendoutId!;
    const dispatch = useAppDispatch();

    const {sendoutToEdit, updatingSendout} = useAppSelector(state => state.editor);
    const isSending = useAppSelector(state => state.sendouts.startingSendout);
    const currentUser = useAppSelector(state => state.users.currentUserInfo);
    const sendingBlocked = useAppSelector(state => state.config.sendoutsBlockingEnabled) === true && currentUser?.isAdmin !== true;
    const newUiEnabled = useAppSelector(state => state.misc.newUi);
    const currentUserIsAdmin = currentUser?.isAdmin ?? false;
    const advancedEditorIsAvailable = currentUserIsAdmin || (currentUser?.featureRoles.includes("ADVANCED_EDITOR") ?? false);
    const processorsEditorIsAvailable = currentUserIsAdmin || (currentUser?.featureRoles.includes("PROCESSORS_EDITOR") ?? false);
    const newFeaturesAreAvailable = currentUserIsAdmin || (currentUser?.featureRoles.includes("FEATURE_TESTER") ?? false);

    const [title, setTitle] = useState<string | undefined>(sendoutToEdit?.title);
    const [sender, setSender] = useState<string | undefined>(sendoutToEdit?.creatorName);
    const [author, setAuthor] = useState<string | null>(getCurrentAuthorName(sendoutToEdit?.processedBody));
    const [preheader, setPreheader] = useState<string | undefined>(sendoutToEdit?.emailPreheader);
    const [campaignId, setCampaignId] = useState<number | undefined>(sendoutToEdit?.targetCampaignId);
    const [currentCampaignInfo, setCurrentCampaignInfo] = useState<MarketoCampaign | null>(null);
    const [forcedFeaturedImage, setForcedFeaturedImage] = useState(sendoutToEdit?.forcedFeaturedImage);

    const [iframeState, setIframeState] = useState(0);
    const iframeRef = useRef<HTMLIFrameElement>(null);
    const [showCampaignSelector, setCampaignSelectorVisibility] = useState(false);
    const [showRawHtmlEditDialog, setShowRawHtmlEditDialog] = useState(false);
    const [showProcessedHtmlEditDialog, setShowProcessedHtmlEditDialog] = useState(false);
    const [showSampleModal, setShowSampleModal] = useState(false);
    const [unresolvedIssues, setUnresolvedIssues] = useState(false);
    const [contentStatus, setContentStatus] = useState<ContentStatus | null>(null);
    const [showDebugger, setShowDebugger] = useState(false);
    const [invalidCampaign, setInvalidCampaignFlag] = useState(false);
    const [showProcessorsEditor, setShowProcessorsEditor] = useState(false);
    const [updatingPreview, setUpdatingPreview] = useState(false);
    const [showSchedulingModal, setShowSchedulingModal] = useState(false);
    const [showReadMoreModal, setShowReadMoreModal] = useState(false);

    const errorsCount = sendoutToEdit?.issues.filter(issue => issue.severity === "ERROR").length ?? 0
    const sendingAllowed = !sendingBlocked && sendoutToEdit?.targetCampaignId != null && errorsCount === 0 && !unresolvedIssues;
    const authorNameIsInTheText = sendoutToEdit && checkIfAuthorNameIsDisplayed(sendoutToEdit.processedBody);
    const authorNameChanged = !!author && author !== getCurrentAuthorName(sendoutToEdit?.processedBody);

    const changes: SendoutsApi.PatchSendoutRequest = {
        targetCampaignId: campaignId !== sendoutToEdit?.targetCampaignId && campaignId !== null ? campaignId : undefined,
        title: title !== sendoutToEdit?.title ? title : undefined,
        creatorName: sender !== sendoutToEdit?.creatorName ? sender : undefined,
        preheader: preheader !== sendoutToEdit?.emailPreheader ? preheader : undefined,
    };

    const saveButtonEnabled = Object.keys(changes).some(key => (changes as any)[key] !== undefined) || authorNameChanged;

    useEffect(() => {
        if (sendoutToEdit === null || sendoutIdFromUrl !== sendoutToEdit?.id) {
            dispatch(editorActions.clear());
            dispatch(editorActions.loadSendout(sendoutIdFromUrl));
        }
    }, [sendoutIdFromUrl]);

    useEffect(() => {
        if (sendoutToEdit !== null) {
            setCampaignId(sendoutToEdit.targetCampaignId);
            setTitle(sendoutToEdit.title);
            setSender(sendoutToEdit.creatorName);
            setPreheader(sendoutToEdit.emailPreheader);
            setAuthor(getCurrentAuthorName(sendoutToEdit.processedBody));
            ContentApi.checkPostStatus(sendoutToEdit.contentId).subscribe(setContentStatus);
            setForcedFeaturedImage(sendoutToEdit.forcedFeaturedImage);
        }
    }, [sendoutToEdit]);

    useEffect(() => {
        if (campaignId) {
            setInvalidCampaignFlag(false);
            ConfigApi.getCampaign(campaignId).subscribe({
                next: setCurrentCampaignInfo,
                error: err => {
                    if (err?.response?.status === 404) {
                        setInvalidCampaignFlag(true)
                        setCampaignSelectorVisibility(true);
                    }
                },
            });
        } else {
            setCurrentCampaignInfo(null);
        }
    }, [campaignId]);

    const reloadIframe = () => {
        setUpdatingPreview(true);
        setIframeState(prevState => prevState + 1);
    };

    useEffect(reloadIframe, [sendoutToEdit?.processedBody, sendoutToEdit?.targetCampaignId, sendoutToEdit?.title]);

    const scheduleCampaignAsap = () => {
        confirm({
            text: "Run the campaign?",
            description: <div>
                <p>
                    In case of any issues, only <b>#email-marketing</b> team members will be able to manually stop a sendout.
                </p>
                {contentStatus?.sent && <S.ContentAlreadySentWarning>
                    <Icon Type={RiErrorWarningLine}/> WARNING: this content is marked as already sent!
                </S.ContentAlreadySentWarning>}
                {contentStatus?.scheduled && <S.ContentAlreadySentWarning>
                    <Icon Type={RiErrorWarningLine}/> WARNING: this content is marked as scheduled to be sent!
                </S.ContentAlreadySentWarning>}
            </div>,
            confirmLabel: "Yep, I'm absolutely sure",
            rejectLabel: "Cancel",
        }).then(() => dispatch(sendoutsActions.runSendout({sendoutId: sendoutToEdit!.id, ignorePauseInterval: false})))
            .catch(() => {});
    };

    const scheduleCampaignAtSpecificDate = (date: Date) => {
        dispatch(sendoutsActions.runSendout({
            sendoutId: sendoutToEdit!.id,
            ignorePauseInterval: false,
            date,
        }));
    };

    const onCampaignSelected = (campaignId: number) => {
        setCampaignSelectorVisibility(false);
        setCampaignId(campaignId);

        // if the previous campaign was invalid – save changes immediately:
        if (invalidCampaign) {
            dispatch(editorActions.patchSendout({sendoutId: sendoutToEdit!.id, request: {targetCampaignId: campaignId}}));
        }
    };

    const saveSettings = () => {
        if (authorNameChanged) {
            if (!author) {
                throw Error("Unable to set blank author name");
            }
            const processedBody = changes.processedHtml ?? sendoutToEdit!.processedBody;
            const updatedBody = setAuthorNameInContent(processedBody, author);
            changes.processedHtml = updatedBody;
        }
        dispatch(editorActions.patchSendout({sendoutId: sendoutToEdit!.id, request: changes}));
    };

    const onIframeLoaded = () => {
        setUpdatingPreview(false);
        // resize iframe:
        const iframe = iframeRef.current;
        if (iframe) {
            try {
                iframe.style.height = iframe.contentWindow!.document.documentElement.scrollHeight + 'px';
            } catch (e) {
                // it will be blocked by browser while running on dev-server
            }
        }
    };

    const onProcessedHtmlEditModalClosed = useCallback((edited?: boolean) => {
        setShowProcessedHtmlEditDialog(false);
    }, []);

    const onRawHtmlEditModalClosed = useCallback((edited?: boolean) => {
        setShowRawHtmlEditDialog(false);
    }, []);

    const switchAuthorNameInText = (visible: boolean) => {
        const updatedProcessedContent = changeAuthorNameVisibility(sendoutToEdit!.processedBody, visible);
        if (updatedProcessedContent) {
            dispatch(editorActions.patchSendout({sendoutId: sendoutToEdit!.id, request: {processedHtml: updatedProcessedContent}}));
        }
    };

    function toggleForcedFeaturedImage() {
        dispatch(editorActions.patchSendout({sendoutId: sendoutToEdit!.id, request: {forcedFeaturedImage: !forcedFeaturedImage}}));
    }

    function reloadContentFromBlog(): void {
        confirm({
            text: "Reload the content?",
            description: <div>
                <p>Use this feature if you need to update the sendout with the most recent changes appeared in the original blogpost.</p>
                <p>Please note that <b>you'll lose all changes</b> you made in the body of the current sendout.</p>
            </div>,
            confirmLabel: "Reload",
            rejectLabel: "Cancel",
        }).then(() => dispatch(editorActions.reloadSendoutContentFromBlog(sendoutToEdit!.id)));
    }

    return <S.EditorPageLayout>
        {sendoutToEdit && <>

            <S.EditorLayout>

                <S.ControlsContainer newUi={newUiEnabled}>

                    <div>
                        <p style={{margin: "16px 0 0", textAlign: "center"}}>
                            Selected Email-campaign:
                            <CampaignSelectionButton onClick={() => setCampaignSelectorVisibility(true)}
                                                     newUi={newUiEnabled}>
                                {campaignId ? (currentCampaignInfo ? `${currentCampaignInfo.name} (${currentCampaignInfo.description})` : `#${campaignId}`) : "none"}
                            </CampaignSelectionButton>
                        </p>

                        <Input
                            value={title ?? ""}
                            label="Subject line"
                            style={{marginBottom: "2px"}}
                            onChange={(e) => setTitle(e.target.value)}
                            size={Size.FULL}
                        />

                        <Input
                            value={preheader ?? ""}
                            label="Preheader"
                            style={{marginBottom: "2px"}}
                            onChange={(e) => setPreheader(e.target.value)}
                            size={Size.FULL}
                        />

                        <Input
                            value={sender ?? ""}
                            label="Sender"
                            style={{marginBottom: "2px"}}
                            onChange={(e) => setSender(e.target.value)}
                            size={Size.FULL}
                        />
                        <Input
                            value={author ?? ""}
                            label="Author name (displayed in the email body)"
                            style={{marginBottom: "12px"}}
                            onChange={(e) => setAuthor(e.target.value)}
                            size={Size.FULL}
                            disabled={authorNameIsInTheText !== true}
                        />
                        <Toggle
                            disabled={authorNameIsInTheText === null}
                            defaultChecked={authorNameIsInTheText ?? false}
                            onChange={e => switchAuthorNameInText(e.target.checked)}
                        >
                            <S.ToggleLabel>Display author name in the email body</S.ToggleLabel>
                        </Toggle>

                        <div>
                            <Toggle
                                disabled={!sendoutToEdit?.image}
                                defaultChecked={forcedFeaturedImage ?? false}
                                checked={forcedFeaturedImage ?? false}
                                onChange={toggleForcedFeaturedImage}
                            >
                                <S.ToggleLabel>Place a featured image to the top</S.ToggleLabel>
                            </Toggle>
                        </div>

                        {newUiEnabled
                            ? <MultiButton
                                onClick={saveSettings}
                                disabled={!saveButtonEnabled}
                                style={{display: "block", margin: "16px auto 0", maxWidth: "300px"}}
                            >
                                <Icon Type={FaSave}/> Save changes
                            </MultiButton>
                            : <Button
                                primary
                                onClick={saveSettings}
                                disabled={!saveButtonEnabled}
                                style={{display: "block", margin: "auto"}}
                            >
                                <Icon Type={FaSave}/> Save changes
                            </Button>
                        }

                    </div>

                    <SendoutIssuesList
                        issues={sendoutToEdit.issues}
                        onIssuesStatusChanged={(resolved) => setUnresolvedIssues(!resolved)}
                    />

                    <ButtonsDock
                        options={[
                            {
                                title: "Tune",
                                description: "Advanced editors",
                                icon: FaEdit,
                                submenu: [
                                    {label: "Reload content from the blog", fn: reloadContentFromBlog},
                                    {label: "Edit raw content", fn: () => setShowRawHtmlEditDialog(true)},
                                    ...(advancedEditorIsAvailable ? [{label: "Edit generated content [pro]", fn: () => setShowProcessedHtmlEditDialog(true)}] : []),
                                    ...(processorsEditorIsAvailable ? [{label: "Edit content processors [pro]", fn: () => setShowProcessorsEditor(true)}] : []),
                                    {label: "Edit 'Read More' position", fn: () => setShowReadMoreModal(true)},
                                ],
                                popoverLeftOffset: 0,
                            },
                            {
                                title: "Verify",
                                description: "Samples & tests",
                                icon: FaCheck,
                                submenu: [
                                    {label: "Send sample email", fn: () => setShowSampleModal(true)},
                                    ...(currentUserIsAdmin ? [{label: "Show debugger [pro]", fn: () => setShowDebugger(true)}] : []),
                                ],
                                popoverLeftOffset: 0,
                            },
                            {
                                title: "Send",
                                description: "Ready, set, go!",
                                icon: RiMailSendFill,
                                submenu: [
                                    {label: "Send now", fn: scheduleCampaignAsap},
                                    {label: "Send later", fn: () => setShowSchedulingModal(true)},
                                ],
                                popoverLeftOffset: -40,
                                disabled: !sendingAllowed || isSending,
                            },
                        ]}
                    />

                </S.ControlsContainer>

                <S.PreviewContainer>
                    {sendoutToEdit.targetCampaignId && <>
                        <h2>{unescapeString(sendoutToEdit.title)}</h2>
                        <S.PreviewSenderInfo>
                            From: <b>{sendoutToEdit.creatorName}</b> <i>&lt;news@jetbrains.com&gt;</i>
                        </S.PreviewSenderInfo>
                        <div style={{position: "relative"}}>
                            {(updatingSendout || updatingPreview) && <S.PreviewOverlay>
                                <S.LoaderContainer>
                                    <Loader/>
                                    <p>Updating the sendout...</p>
                                </S.LoaderContainer>
                            </S.PreviewOverlay>}
                            {iframeState !== 0 && <S.IFrame
                                key={iframeState}
                                src={generatePreviewUrl(sendoutToEdit!.id)}
                                ref={iframeRef}
                                onLoad={onIframeLoaded}
                                style={newUiEnabled ? {} : {}}
                            />}
                        </div>
                    </>}
                </S.PreviewContainer>

            </S.EditorLayout>

            <CampaignSelectorDialog
                show={showCampaignSelector}
                productTypes={[sendoutToEdit.product]}
                onClose={() => setCampaignSelectorVisibility(false)}
                onCampaignSelected={onCampaignSelected}
                currentCampaignIsInvalid={invalidCampaign}
            />
            <GetEmailSampleModal open={showSampleModal} onClose={() => setShowSampleModal(false)} sendoutId={sendoutToEdit.id}/>
            <EditContentModal
                show={showProcessedHtmlEditDialog}
                sendoutId={sendoutToEdit.id}
                initialHtml={sendoutToEdit.processedBody}
                onClose={onProcessedHtmlEditModalClosed}
                contentType="processed"
            />
            <EditContentModal
                show={showRawHtmlEditDialog}
                sendoutId={sendoutToEdit.id}
                initialHtml={sendoutToEdit.rawBody}
                onClose={onRawHtmlEditModalClosed}
                contentType="raw"
            />
            <ContentDebuggerModal
                show={showDebugger}
                sendoutId={sendoutToEdit.id}
                onClose={() => setShowDebugger(false)}
            />
            <ScheduleCampaignModal
                open={showSchedulingModal}
                onClose={() => setShowSchedulingModal(false)}
                onDateConfirmed={scheduleCampaignAtSpecificDate}
                alreadySent={contentStatus?.sent ?? false}
                alreadyScheduled={contentStatus?.scheduled ?? false}
            />
            {currentCampaignInfo && <SendoutProcessorsEditorDialog
                show={showProcessorsEditor}
                onClose={() => setShowProcessorsEditor(false)}
                sendoutId={sendoutToEdit.id}
                currentConfig={sendoutToEdit.processorsConfig}
                campaign={currentCampaignInfo}
            />}
            <ReadMoreCustomizationModal
                sendout={sendoutToEdit}
                show={showReadMoreModal}
                onClose={() => setShowReadMoreModal(false)}
            />

        </>}
    </S.EditorPageLayout>;

};
