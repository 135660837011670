import styled from "styled-components";

export const EditorContainer = styled.div`
  height: 70vh;
  overflow-y: auto;
  background-color: #303841;
  
  
  // tweaks for disabling horizontal scrolling:
  max-width: 100%;
  .cm-line {
    word-break: break-word;
    white-space: normal !important;
    overflow-wrap: break-word !important;
  }
  .cm-content {
    max-width: calc(100% - 50px);
  }
  .cm-editor {
    max-width: 100%;
  }
  
`;