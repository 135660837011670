import styled from "styled-components";

export const SendoutInfoContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  
  @media (max-width: 600px) {
    flex-flow: column nowrap;
  }
`;

export const ListItem = styled.div<{recent: boolean}>`
  margin: 10px 0;
  padding: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  background-color: ${props => props.recent ? "#fffade" : "transparent"};
  
  .sendout-img {
    width: 128px;
    height: 60px;
    flex-shrink: 0;
  }
  
  & p {
    margin: 0;
  }
  
  .sendout-actions-block {
    //padding-top: 8px;
    height: 0;
    overflow: hidden;
    transition: height 0.3s cubic-bezier(.25,.8,.25,1);
    display: flex;
    flex-flow: row wrap;
    gap: 4px;
    &::before {
      content: "";
      flex-grow: 1;
    }
  }

  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    .sendout-actions-block {
      height: 28px;
    }
  }
  
`;

export const MetadataContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 4px;
  margin: 8px 0;
`;

export const SendoutMetadata = styled.p`
  font-size: 0.8em;
`;

export const Badge = styled.div`
  padding: 8px 16px;
  border-radius: 8px;
  font-size: 0.8em;
  min-width: 110px;
  text-align: center;
`;

// TODO: move to common?
export const Card = styled.div`
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  width: 400px;
  height: 460px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  background-color: whitesmoke;
  position: relative;
`;

export const Image = styled.img<{grayscale?: boolean}>`
  width: 100%;
  height: 225px;
  ${props => props.grayscale ? `filter: grayscale(1);` : ""}
  transition: all ease-in-out 400ms;
  flex-shrink: 0;
  &:hover {
    ${props => props.grayscale ? `filter: grayscale(0.75);` : ""}
  }
`;

export const Line = styled.div<{background: string, fontColor?: string}>`
  position: absolute;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 0.9em;
  font-weight: bold;
  width: 400px;
  background-color: ${props => props.background};
  color: black;
  transform: rotate(-45deg);
  top: 40px;
  left: -150px;
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 1em;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
`;

export const DataContainer = styled.div`
  padding: 4px 16px;
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
  flex-shrink: 0;
`;

export const Meta = styled.p`
  margin: 0;
  color: #777;
  font-size: 0.8em;
  text-align: center;  
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex-shrink: 0;
`;

export const Button = styled.button`
    background-color: #4e9fe1;
    border: none;
    color: ghostwhite;
    height: 48px;
    line-height: 48px;
    font-size: 1em;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
    transition: background-color ease-in-out 200ms;

    &:hover {
        background-color: #0380E5;
    }
`;

export const IconButton = styled(Button)<{mainColor: string, hoverColor: string}>`
    background-color: ${props => props.mainColor};
    width: 56px;
    &:hover {
      background-color: ${props => props.hoverColor};
    }
`;