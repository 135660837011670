import styled from "styled-components";

export const ControlsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
`;

export const CategoriesList = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 48px;
  padding: 8px;
`;

export const MetricsList = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 40px;
  justify-content: center;
`;

export const HoverContainer = styled.div`
  font-size: 0.85em;
  display: none;
  white-space: nowrap;
`;

export const MetricValue = styled.p`
  font-size: 3em;
  margin: 0;
  font-weight: 200;
`;

export const MetricLabel = styled.p`
  margin: 0;
`;

export const MetricContainer = styled.div<{hover: boolean}>`
  text-align: center;
  background-color: #f8f8f8;
  width: 250px;
  height: 135px;
  padding: 20px;
  border: 3px solid #DDD;
  border-radius: 32px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.12), 0 10px 10px rgba(0, 0, 0, 0.11);
  //flex: 1 0 250px;
  flex-grow: 1;

  &:hover {
    overflow: auto;
  }

  &:hover ${HoverContainer} {
    display: block;
  }

  &:hover ${MetricValue} {
    display: ${props => props.hover ? "none" : "block"};
  }

  &:hover ${MetricLabel} {
    ${props => props.hover ? `&::after {
      content: ":";
      display: inline-block;
    }` : ``}
  }
`;

export const VisitsCount = styled.span`
  display: inline-block;
  background-color: #1b93f5;
  color: white;
  min-width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
`;

export const LinkOpeningsContainer = styled.div`
  padding: 20px;
  border: 3px solid #DDD;
  border-radius: 32px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.12), 0 10px 10px rgba(0, 0, 0, 0.11);
  background-color: #f8f8f8;
  //flex: 3 0 250px;
  flex-grow: 3;
  & p > a {
    color: #1b93f5 !important;
  }
`;
