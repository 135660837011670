import styled from "styled-components";

export const Panel = styled.div`
  padding: 12px;
  margin: 6px 0;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
`;

export const Header = styled.div`
  cursor: pointer;
`;

export const Content = styled.div`
  
`;

export const Arrow = styled.span<{expanded: boolean}>`
  display: inline-block;
  color: lightgray;
  transition: all ease-in-out 100ms;
  transform: rotate(${props => props.expanded ? "0" : "-90deg"});
`;
