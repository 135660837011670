import {configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import {createEpicMiddleware} from "redux-observable";
import {contentReducer} from "./slices/content-slice";
import {rootEpic} from "./epics/root-epic";
import {sendoutsReducer} from "./slices/sendouts-slice";
import {editorReducer} from "./slices/editor-slice";
import {usersReducer} from "./slices/users-slice";
import {miscReducer} from "./slices/misc-slice";
import {configReducer} from "./slices/config-slice";
import subscribeActionMiddleware from "redux-subscribe-action";

const epicsMiddleware = createEpicMiddleware();

export const store = configureStore({
    reducer: {
        content: contentReducer,
        sendouts: sendoutsReducer,
        editor: editorReducer,
        users: usersReducer,
        misc: miscReducer,
        config: configReducer,
    },
    middleware: [epicsMiddleware, subscribeActionMiddleware],
});

epicsMiddleware.run(rootEpic);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
