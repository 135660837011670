import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {
    CancelScheduledSendoutActionPayload,
    CopySendoutActionPayload,
    CreateSendoutActionPayload,
    DeleteSendoutActionPayload,
    LoadSendoutsActionPayload,
    MarkSendoutAsAbortedActionPayload,
    OnSendoutCopiedActionPayload,
    RunSendoutActionPayload,
    sendoutsActions,
    SendSampleActionPayload
} from "../slices/sendouts-slice";
import {catchError, from, ignoreElements, map, of, switchMap, tap} from "rxjs";
import {PayloadAction} from "@reduxjs/toolkit";
import {SendoutsApi} from "../../api/sendouts-api";
import {miscActions} from "../slices/misc-slice";
import {Sendout} from "../../models/sendout";
import {history} from "../../components/CustomRouter/CustomRouter";
import {AppPage} from "../../models/pages";
import alertService from "@jetbrains/ring-ui/dist/alert-service/alert-service";
import {CONFIG} from "../../config";
import {RootState} from "../store";
import {getRecommendedPageSize} from "../../utils/utils";

const onInit$: Epic = (action$) => action$.pipe(
    ofType(miscActions.onInit),
    map(sendoutsActions.getOverallStatus),
);

const createSendout$: Epic = (action$) => action$.pipe(
    ofType(sendoutsActions.createSendout),
    switchMap((action: PayloadAction<CreateSendoutActionPayload>) => SendoutsApi.createSendout(action.payload.contentId, action.payload.title, action.payload.campaignId).pipe(
        map(sendout => sendoutsActions.onSendoutCreated(sendout)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onSendoutCreated$: Epic = (action$) => action$.pipe(
    ofType(sendoutsActions.onSendoutCreated),
    tap((action: PayloadAction<Sendout>) => {
        history.push(`${AppPage.EDITOR}/${(action.payload.id)}`);
        alertService.successMessage("New sendout was created, feel free to customize it before starting", CONFIG.defaultAlertsTimeoutMs);
    }),
    ignoreElements(),
);

const loadSendouts$: Epic = (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType(sendoutsActions.loadSendouts),
    switchMap((action: PayloadAction<LoadSendoutsActionPayload>) => SendoutsApi.getSendouts(
        action.payload.page,
        state$.value.misc.newUi ? getRecommendedPageSize() : 10,
        state$.value.sendouts.sendoutsStateFilter,
        state$.value.sendouts.sendoutsProductFilter,
        state$.value.sendouts.onlyOwnSendoutsFilter,
    ).pipe(
        map(sendoutsPage => sendoutsActions.onSendoutsLoaded(sendoutsPage)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const cancelScheduledSendout$: Epic = (action$) => action$.pipe(
    ofType(sendoutsActions.cancelScheduledSendout),
    switchMap((action: PayloadAction<CancelScheduledSendoutActionPayload>) => SendoutsApi.cancelScheduledSendout(action.payload.sendoutId).pipe(
        map(() => sendoutsActions.onScheduledSendoutCancelled()),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onScheduledSendoutCancelled$: Epic = (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType(sendoutsActions.onScheduledSendoutCancelled),
    switchMap(() => from([
        sendoutsActions.loadSendouts({page: state$.value.sendouts.sendoutsPage!.number}),
        sendoutsActions.updateRecentSendoutsCount(),
    ])),
    tap(() => {
        alertService.successMessage("Scheduled sendout has been cancelled", CONFIG.defaultAlertsTimeoutMs);
    }),
);

const runSendout$: Epic = (action$) => action$.pipe(
    ofType(sendoutsActions.runSendout),
    switchMap((action: PayloadAction<RunSendoutActionPayload>) => SendoutsApi.runSendout(action.payload.sendoutId, action.payload.ignorePauseInterval, action.payload.date ?? null).pipe(
        map(() => sendoutsActions.onSendoutStarted()),
        catchError((response) => {
            alertService.error("Marketo campaign startup failure. Ask MAU Team for assistance");
            return of(miscActions.onError(response));
        }),
    )),
);

const onSendoutStarted$: Epic = (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType(sendoutsActions.onSendoutStarted),
    tap(() => {
        history.push(AppPage.SENDOUTS);
        alertService.successMessage("Marketo campaign has been started", CONFIG.defaultAlertsTimeoutMs);
    }),
    switchMap(() => from([
        sendoutsActions.updateRecentSendoutsCount(),
        sendoutsActions.loadSendouts({page: state$.value.sendouts.sendoutsPage?.number ?? 0}),
    ])),
);

const deleteSendout$: Epic = (action$) => action$.pipe(
    ofType(sendoutsActions.deleteSendout),
    switchMap((action: PayloadAction<DeleteSendoutActionPayload>) => SendoutsApi.deleteSendout(action.payload.sendoutId).pipe(
        map(() => sendoutsActions.onSendoutDeleted()),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onSendoutDeleted$: Epic = (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType(sendoutsActions.onSendoutDeleted),
    map(() => sendoutsActions.loadSendouts({page: state$.value.sendouts.sendoutsPage!.number})),
    tap(() => {
        alertService.successMessage("Sendout has been deleted", CONFIG.defaultAlertsTimeoutMs);
    }),
);

const sendSample$: Epic = (action$) => action$.pipe(
    ofType(sendoutsActions.sendSample),
    switchMap((action: PayloadAction<SendSampleActionPayload>) => SendoutsApi.sendSample(action.payload.sendoutId, action.payload.customEmail ?? null).pipe(
        map(() => sendoutsActions.onSampleSent()),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onSampleSent$: Epic = (action$) => action$.pipe(
    ofType(sendoutsActions.onSampleSent),
    tap(() => {
        alertService.successMessage("A sample message has been sent", CONFIG.defaultAlertsTimeoutMs);
    }),
    ignoreElements(),
);

const setSendoutsStateFilter$: Epic = (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType(sendoutsActions.setSendoutStateFilter),
    map(() => sendoutsActions.loadSendouts({page: state$.value.sendouts.sendoutsPage?.number ?? 0})),
);

const setSendoutsProductFilter$: Epic = (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType(sendoutsActions.setSendoutsProductFilter),
    map(() => sendoutsActions.loadSendouts({page: state$.value.sendouts.sendoutsPage?.number ?? 0})),
);

const setOnlyOwnSendoutsFilter$: Epic = (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType(sendoutsActions.setOnlyOwnSendoutsFilter),
    map(() => sendoutsActions.loadSendouts({page: state$.value.sendouts.sendoutsPage?.number ?? 0})),
);

const copySendout$: Epic = (action$) => action$.pipe(
    ofType(sendoutsActions.copySendout),
    switchMap((action: PayloadAction<CopySendoutActionPayload>) => SendoutsApi.copySendout(action.payload.sendoutId).pipe(
        map((sendout) => sendoutsActions.onSendoutCopied({sendout})),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onSendoutCopied$: Epic = (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType(sendoutsActions.onSendoutCopied),
    tap((action: PayloadAction<OnSendoutCopiedActionPayload>) => {
        history.push(`${AppPage.EDITOR}/${(action.payload.sendout.id)}`);
        alertService.successMessage("Sendout was copied successfully", CONFIG.defaultAlertsTimeoutMs);
    }),
    map(() => sendoutsActions.loadSendouts({page: state$.value.sendouts.sendoutsPage?.number ?? 0})),
);

const updateRecentSendoutsCount$: Epic = (action$) => action$.pipe(
    ofType(sendoutsActions.updateRecentSendoutsCount),
    switchMap(() => SendoutsApi.getRecentSendoutsCount().pipe(
        map((count) => sendoutsActions.onRecentSendoutsCountLoaded(count)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const loadOverallStatus$: Epic = (action$) => action$.pipe(
    ofType(sendoutsActions.getOverallStatus),
    switchMap(() => SendoutsApi.getOverallStatus().pipe(
        map(sendoutsActions.onOverallStatusLoaded),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const markAsAborted$: Epic = (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType(sendoutsActions.markAsAborted),
    switchMap((action: PayloadAction<MarkSendoutAsAbortedActionPayload>) => SendoutsApi.markAsAborted(action.payload.sendoutId).pipe(
        map(() => sendoutsActions.loadSendouts({page: state$.value.sendouts.sendoutsPage?.number ?? 0})),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

export const sendoutsEpics = combineEpics(
    onInit$,
    createSendout$,
    onSendoutCreated$,
    loadSendouts$,
    cancelScheduledSendout$,
    onScheduledSendoutCancelled$,
    runSendout$,
    onSendoutStarted$,
    deleteSendout$,
    onSendoutDeleted$,
    sendSample$,
    onSampleSent$,
    setSendoutsStateFilter$,
    setSendoutsProductFilter$,
    setOnlyOwnSendoutsFilter$,
    copySendout$,
    onSendoutCopied$,
    updateRecentSendoutsCount$,
    loadOverallStatus$,
    markAsAborted$,
);
