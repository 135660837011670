import styled from "styled-components";

export const ButtonsContainer = styled.div`
  margin-top: 20px;
`;

export const DevicesContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 12px;
  max-width: 1400px;
  margin: 16px 0;
  &>* {
    flex: 1 1 32%;
  }
`;