import React, {useState} from "react";
import * as S from "./styles";
import {Icon} from "../Icon/Icon";
import {FaChevronDown} from "react-icons/fa";

interface Props {
    children: React.ReactNode;
    title: string;
    initiallyExpanded?: boolean;
}

export const TogglePanel: React.FC<Props> = (props) => {

    const {children, title, initiallyExpanded} = props;
    const [expanded, setExpanded] = useState(initiallyExpanded ?? false);

    const toggle = () => setExpanded(!expanded);

    return <S.Panel>
        <S.Header onClick={toggle}><S.Arrow expanded={expanded}><Icon Type={FaChevronDown}/></S.Arrow> {title}</S.Header>
        {expanded && <S.Content>{children}</S.Content>}
    </S.Panel>

};
