import React from "react";
import * as S from "./styles";
import Button from "@jetbrains/ring-ui/dist/button/button";

export interface ModalAction {
    fn(): void;
    disabled?: boolean;
    label: string;
    primary?: boolean;
    loading?: boolean;
    danger?: boolean;
}

export enum ModalWidth {
    DEFAULT,
    WIDE,
}

interface Props {
    show: boolean;
    title: string | JSX.Element;
    onClose(): void;
    children: React.ReactNode;
    actions?: ModalAction[];
    modalWidth?: ModalWidth;
    background?: string;
    closeButtonInFooter?: boolean;
}

export const Modal: React.FC<Props> = (props) => {

    const {
        show,
        title,
        onClose,
        children,
        actions,
        modalWidth,
        background,
        closeButtonInFooter,
    } = props;

    const width = modalWidth === ModalWidth.WIDE ? "85vw" : "550px";
    const showCloseButton = closeButtonInFooter ?? true;

    return <S.Dialog
        show={show}
        onCloseAttempt={onClose}
        showCloseButton
        closeButtonInside
        width={width}
        background={background ?? "white"}
    >
        <S.ModalContainer>
            <S.Title>{title}</S.Title>
            <S.Content>
                {children}
            </S.Content>
        </S.ModalContainer>
        {(showCloseButton || (actions && actions.length > 0)) && <S.ActionsBlock>
            {actions?.map((action, idx) => <Button
                key={action.label}
                onClick={action.fn}
                disabled={action.disabled}
                primary={action.primary}
                danger={action.danger}
                loader={action.loading}
            >
                {action.label}
            </Button>)}
            {showCloseButton && <Button onClick={onClose}>Close</Button>}
        </S.ActionsBlock>}
    </S.Dialog>

};
