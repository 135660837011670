import {FeatureRole, User} from "../../models/user";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {CurrentUserInfo} from "../../models/current-user-info";
import {ProductDescriptor} from "../../models/product-descriptor";

interface UsersState {
    users: User[] | null;
    currentUserInfo: CurrentUserInfo | null;
}

const initialUsersState: UsersState = {
    users: null,
    currentUserInfo: null,
};

const usersSlice = createSlice({
    name: "users",
    initialState: initialUsersState,
    reducers: {
        loadUsers: () => {},
        onUsersLoaded: (state, action: PayloadAction<User[]>) => {
            state.users = action.payload;
        },
        loadCurrentUserInfo: () => {},
        onCurrentUserInfoLoaded: (state, action: PayloadAction<CurrentUserInfo>) => {
            state.currentUserInfo = action.payload;
        },
        createNewUser: (state, action: PayloadAction<NewUserActionPayload>) => {},
        onUserCreated: (state, action: PayloadAction<User>) => {},
        deleteUser: (state, action: PayloadAction<DeleteUserActionPayload>) => {},
        onUserDeleted: () => {},
        changeUserProducts: (state, action: PayloadAction<EditUserActionPayload>) => {},
        onUserProductsChanged: (state, action: PayloadAction<User>) => {},
    },
});

export const usersReducer = usersSlice.reducer;

export const usersActions = usersSlice.actions;

export type NewUserActionPayload = {email: string, products: ProductDescriptor[], notifyUser: boolean};
export type EditUserActionPayload = {email: string, products: ProductDescriptor[], featureRoles: FeatureRole[], notifyUser: boolean};
export type DeleteUserActionPayload = {email: string};
