import React from "react";
import * as S from "./styles";

interface Props {
    value: number;
    hideOnZero?: boolean;
    children: React.ReactNode;
}

export const Counter: React.FC<Props> = (props) => {

    const {value, hideOnZero, children} = props;

    return <div style={{display: "inline-block", position: "relative"}}>
        {children}
        {(value !== 0 || !hideOnZero) && <S.Label>{value < 100 ? value : "99+"}</S.Label>}
    </div>;

};
