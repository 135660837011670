import {StatisticsEntryEventType} from "../models/statistics";
import {StatisticsApi} from "../api/statistics-api";

export const StatisticsService = {

    addEntry(type: StatisticsEntryEventType, payload: string | undefined) {
        StatisticsApi.addEntry({type, payload}).subscribe(() => {
            console.log(`Statistics entry [${type}] added`);
        });
    },

    openLinkAndAddEntry(url: string) {
        window.open(url, "_blank");
        this.addEntry(StatisticsEntryEventType.OPEN_LINK, url);
    },

};
