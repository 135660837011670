import React from "react";
import styled from "styled-components";

interface Props {
    text: string;
    backgroundColor?: string;
    fontColor?: string;
}

const TagBlock = styled.div<{backgroundColor: string, fontColor: string}>`
  display: inline-block;
  border: 1px solid #dfe5eb;
  color: ${props => props.fontColor};
  background-color: ${props => props.backgroundColor};
  border-radius: 5px;
  font-size: 0.8em;
  height: 1.6em;
  padding: 1px 6px 3px;
`;

export const Tag: React.FC<Props> = (props) => {

    const backgroundColor = props.backgroundColor ?? "transparent";
    const fontColor = props.fontColor ?? "black";

    return <TagBlock backgroundColor={backgroundColor} fontColor={fontColor}>
        {props.text}
    </TagBlock>;

};
