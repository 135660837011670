import styled from "styled-components";
import RingDialog from "@jetbrains/ring-ui/dist/dialog/dialog";

export const Dialog = styled(RingDialog)<{width: string, background: string}>`
  div[data-test="ring-island ring-dialog"] {
    width: ${props => props.width};
    background: ${props => props.background};
    border-radius: 20px;
  }
`;

export const ModalContainer = styled.div`
  padding: 10px;
`;

export const Title = styled.h2`
  margin: 0 0 10px;
`;

export const ActionsBlock = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 10px;
  padding: 10px;
  &::before {
    content: "";
    flex-grow: 1;
  }
`;

export const Content = styled.div`
  max-height: 70vh;
  overflow: auto;
`;
