import React, {useState} from "react";
import {Modal} from "../Modal/Modal";
import {ProductDescriptor} from "../../models/product-descriptor";
import Select from "@jetbrains/ring-ui/dist/select/select";
import {ContentApi} from "../../api/content-api";
import alertService from "@jetbrains/ring-ui/dist/alert-service/alert-service";
import {CONFIG} from "../../config";
import {useAppSelector} from "../../redux/store";

interface Props {
    show: boolean;
    onClose(): void;
    products: ProductDescriptor[];
}

export const RequestAccessModal: React.FC<Props> = (props) => {

    const {show, onClose, products} = props;
    const [selectedProduct, setSelectedProduct] = useState<ProductDescriptor | null>(null);
    const user = useAppSelector(state => state.users.currentUserInfo);

    const filteredProducts = products.filter(product => !product.permitted && product.label !== "CrossPost");
    const hasEverything = user?.isAdmin || filteredProducts.length === 0;

    const send = () => {
        if (!selectedProduct) {
            throw Error("An attempt to submit an invalid form");
        }
        ContentApi.requestProductAccess(selectedProduct).subscribe(() => {
            alertService.successMessage(
                "Your request has been sent successfully",
                CONFIG.defaultAlertsTimeoutMs,
            );
            onClose();
        });
    };

    return <Modal
        show={show}
        title="Request product access"
        onClose={onClose}
        actions={hasEverything ? [] : [{
            label: "Send",
            disabled: !selectedProduct,
            fn: send,
            primary: true,
        }]}
    >
        <div>
            {hasEverything && <p>Well, it looks like you already have access to everything 🤔</p> || <>
                <p style={{fontSize: "0.8em"}}>(You'll get a notification when your permissions will be updated)</p>
                <Select
                    selected={selectedProduct ? {label: selectedProduct.label, key: selectedProduct.value, value: selectedProduct} : null}
                    data={filteredProducts.map(product => ({label: product.label, key: product.value, value: product}))}
                    onChange={(product: any) => setSelectedProduct(product.value)}
                />
            </>}
        </div>
    </Modal>;

};
