import React from "react";
import {ContentDescriptor} from "../../models/content-descriptor";
import * as S from "./styles";
import defaultImg from "./placeholder.png";
import Button from "@jetbrains/ring-ui/dist/button/button";
import {Icon} from "../Icon/Icon";
import {FaCheckDouble} from "react-icons/fa";
import {Tag} from "../Tag/Tag";
import Tooltip from "@jetbrains/ring-ui/dist/tooltip/tooltip";
import {MdSchedule} from "react-icons/md";
import {useAppSelector} from "../../redux/store";
import {ProductDescriptor} from "../../models/product-descriptor";

interface Props {
    posts: ContentDescriptor[];
    onCreate(post: ContentDescriptor): void;
    onDelete(post: ContentDescriptor): void;
    showProductLabel: boolean;
}

export const PostsList: React.FC<Props> = (props) => {

    const {posts, onCreate, onDelete, showProductLabel} = props;
    const isAdmin = useAppSelector(state => state.users.currentUserInfo?.isAdmin ?? false);
    const knownProducts = useAppSelector(state => state.content.products);
    const creatingSendoutPostId = useAppSelector(state => state.sendouts.creatingSendoutPostId);

    const createSendout = (post: ContentDescriptor) => onCreate(post);

    const deletePost = (post: ContentDescriptor) => onDelete(post);

    return <>
        {posts.map((post, idx) => <S.Post key={post.id}>
            <div style={{display: "flex", flexFlow: "row nowrap", gap: "10px"}}>
                <img src={post.image ?? defaultImg} className="post-image"/>
                <div style={{flexGrow: 1}}>
                    <S.PostHeader>
                        <S.PostLink href={post.link} target="_blank">
                            {(showProductLabel || post.productNames.length > 1) && <Tooltip
                                title={post.productNames.length > 1 ? "This post was published to multiple blogs (crossposted)" : ""}>
                                <S.ProductLabel crossposted={post.productNames.length > 1}>
                                    {post.productNames.map(p => resolveProductLabel(p, knownProducts)).join(" | ")}
                                </S.ProductLabel>
                            </Tooltip>}
                            {post.title}
                        </S.PostLink>
                        <div>
                            <S.PostMetadata>{post.date.toLocaleString("ru-RU")}</S.PostMetadata>
                            <S.PostMetadata>by {post.author}</S.PostMetadata>
                        </div>
                    </S.PostHeader>
                    <div style={{display: "flex", flexFlow: "row nowrap", justifyContent: "space-between"}}>
                        <S.TagsList>
                            {post.tags.map((tag) => <Tag text={tag.name} key={tag.name}/>)}
                        </S.TagsList>
                        {post.scheduled && <S.SentLabel><Icon Type={MdSchedule}/> Scheduled</S.SentLabel>}
                        {post.sent && !post.scheduled && <S.SentLabel><Icon Type={FaCheckDouble}/> Sent</S.SentLabel>}
                    </div>
                </div>
            </div>
            <S.ActionsList>
                <Button
                    onClick={() => createSendout(post)}
                    loader={post.id === creatingSendoutPostId}
                    disabled={creatingSendoutPostId !== null}
                >
                    Create new sendout
                </Button>
                {isAdmin && <Button
                    danger
                    onClick={() => deletePost(post)}
                    disabled={creatingSendoutPostId !== null}
                >
                    Delete this post
                </Button>}
            </S.ActionsList>
        </S.Post>)}
    </>;

};

function resolveProductLabel(productName: string, knownProducts: ProductDescriptor[] | null): string {
    return knownProducts?.find(p => p.value === productName)?.label ?? productName;
}
