import React, {useEffect, useMemo, useState} from "react";
import * as S from "./styles";
import {Modal} from "../Modal/Modal";
import Input, {Size} from "@jetbrains/ring-ui/dist/input/input";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import Loader from "@jetbrains/ring-ui/dist/loader/loader";
import Toggle from "@jetbrains/ring-ui/dist/toggle/toggle";
import {ProductDescriptor} from "../../models/product-descriptor";
import {usersActions} from "../../redux/slices/users-slice";
import Checkbox from "@jetbrains/ring-ui/dist/checkbox/checkbox";
import {Icon} from "../Icon/Icon";
import {AiFillWarning} from "react-icons/ai";
import {debounceTime, Subject, switchMap} from "rxjs";
import {UsersApi} from "../../api/users-api";
import {UserExistenceCheckResponse} from "../../models/user-existence-check-response";

interface Props {
    show: boolean;
    onClose(): void;
}

const EMAIL_SUFFIX = "@jetbrains.com";

export const NewUserModal: React.FC<Props> = (props) => {

    const {show, onClose} = props;
    const [email, setEmail] = useState("");
    const dispatch = useAppDispatch();
    const products = useAppSelector(state => state.content.products);
    const [selectedProducts, setSelectedProducts] = useState<ProductDescriptor[]>([]);
    const [notificationEnabled, setNotificationEnabled] = useState(true);
    const [userExistence, setUserExistence] = useState<UserExistenceCheckResponse | null>(null);

    const formIsValid = emailIsValid(email) && products !== null && selectedProducts.length > 0 && userExistence?.userExists === false;

    const emailChangeSub = useMemo(() => new Subject<string>(), []);

    const errorText = (emailIsValid(email) || email === "")
        ? (userExistence?.userExists === true ? `User ${userExistence.email} already exists` : undefined)
        : `Only valid ${EMAIL_SUFFIX} emails are supported`;

    useEffect(() => {
        const subscription = emailChangeSub.pipe(
            debounceTime(1000),
            switchMap(email => UsersApi.checkUserExistence(email))
        ).subscribe({
            next: value => {
                setUserExistence(value);
            },
        })
        return () => subscription.unsubscribe();
    }, []);

    function onProductSwitch(product: ProductDescriptor, selected: boolean): void {
        if (selected) {
            setSelectedProducts([product, ...selectedProducts]);
        } else {
            setSelectedProducts(selectedProducts.filter(p => p.value !== product.value));
        }
    }

    function create() {
        if (!formIsValid) {
            throw Error("An attempt to submit an invalid form");
        }
        dispatch(usersActions.createNewUser({
            email,
            products: selectedProducts,
            notifyUser: notificationEnabled,
        }));
        onClose();
    }

    function onEmailInputChange(value: string): void {
        setEmail(value);
        setUserExistence(null);
        if (emailIsValid(value)) {
            emailChangeSub.next(value);
        }
    }

    return <Modal
        show={show}
        title="Create a new user"
        onClose={onClose}
        actions={[{
            fn: create,
            label: "Create",
            disabled: !formIsValid,
            primary: true,
        }]}
    >
        <Input
            value={email}
            onChange={e => onEmailInputChange(e.target.value)}
            placeholder="E-Mail"
            size={Size.FULL}
            error={errorText}
        />
        {products !== null && <div>
            <p>Allowed products:</p>
            <div style={{display: "flex", flexFlow: "row wrap"}}>
                {products.filter(p => p.value !== "CrossPost").map(product => <div key={product.value} style={{flex: "32%"}}>
                    <Toggle onChange={e => onProductSwitch(product, e.target.checked)}>
                        <S.ProductLabel>{product.label}</S.ProductLabel>
                    </Toggle>
                </div>)}
            </div>
        </div> || <Loader message="Loading products list..."/>}
        <S.Warning>
            <Icon style={{color: "gold"}} Type={AiFillWarning}/>
            Remember to add the new user to the relevant campaigns in Marketo.
        </S.Warning>
        <S.CheckboxContainer>
            <Checkbox
                label="Send a Slack-notification to the user"
                checked={notificationEnabled}
                onChange={() => setNotificationEnabled(!notificationEnabled)}
            />
        </S.CheckboxContainer>
    </Modal>

};

function emailIsValid(email: string): boolean {
    return email.endsWith(EMAIL_SUFFIX) && email.match(/^[-a-zA-Z0-9.@]+$/) !== null;
}
