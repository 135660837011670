import {combineEpics} from "redux-observable";
import {contentEpics} from "./content-epics";
import {sendoutsEpics} from "./sendouts-epics";
import {editorEpics} from "./editor-epics";
import {usersEpics} from "./users-epics";
import {miscEpics} from "./misc-epics";
import {configEpics} from "./config-epics";

export const rootEpic = combineEpics(
    contentEpics,
    sendoutsEpics,
    editorEpics,
    usersEpics,
    miscEpics,
    configEpics,
);
