import styled from "styled-components";

export const RootLayout = styled.div`
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
`;

export const Main = styled.main`
  padding: 0;
  flex-grow: 1;
  overflow-y: auto;
  background-color: white;
`;
