import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Sendout, SendoutState} from "../../models/sendout";
import {Page} from "../../models/page";
import {ProductDescriptor} from "../../models/product-descriptor";
import {Status} from "../../models/status";

interface SendoutsState {
    sendoutsPage: Page<Sendout> | null;
    startingSendout: boolean;
    sendoutsStateFilter: SendoutState | null;
    sendoutsProductFilter: ProductDescriptor | null;
    onlyOwnSendoutsFilter: boolean;
    creatingSendoutPostId: string | null;
    recentSendoutsCount: number | null;
    overallStatus: Status | null;
}

const initialSendoutsState: SendoutsState = {
    sendoutsPage: null,
    startingSendout: false,
    sendoutsStateFilter: null,
    sendoutsProductFilter: null,
    onlyOwnSendoutsFilter: false,
    creatingSendoutPostId: null,
    recentSendoutsCount: null,
    overallStatus: null,
};

const sendoutsSlice = createSlice({
    name: "sendouts",
    initialState: initialSendoutsState,
    reducers: {
        loadSendouts: (state, action: PayloadAction<LoadSendoutsActionPayload>) => {},
        onSendoutsLoaded: (state, action: PayloadAction<Page<Sendout>>) => {
            state.sendoutsPage = action.payload;
        },
        createSendout: (state, action: PayloadAction<CreateSendoutActionPayload>) => {
            state.creatingSendoutPostId = action.payload.contentId;
        },
        onSendoutCreated: (state, action: PayloadAction<Sendout>) => {
            state.creatingSendoutPostId = null;
        },
        cancelScheduledSendout: (state, action: PayloadAction<CancelScheduledSendoutActionPayload>) => {},
        onScheduledSendoutCancelled: () => {},
        runSendout: (state, action: PayloadAction<RunSendoutActionPayload>) => {
            state.startingSendout = true;
        },
        onSendoutStarted: (state) => {
            state.startingSendout = false;
        },
        deleteSendout: (state, action: PayloadAction<DeleteSendoutActionPayload>) => {},
        onSendoutDeleted: () => {},
        sendSample: (state, action: PayloadAction<SendSampleActionPayload>) => {},
        onSampleSent: () => {},
        setSendoutStateFilter: (state, action: PayloadAction<SendoutState | null>) => {
            state.sendoutsStateFilter = action.payload;
        },
        setSendoutsProductFilter: (state, action: PayloadAction<ProductDescriptor | null>) => {
            state.sendoutsProductFilter = action.payload;
        },
        setOnlyOwnSendoutsFilter: (state, action: PayloadAction<boolean>) => {
            state.onlyOwnSendoutsFilter = action.payload;
        },
        copySendout: (state, action: PayloadAction<CopySendoutActionPayload>) => {},
        onSendoutCopied: (state, action: PayloadAction<OnSendoutCopiedActionPayload>) => {},
        updateRecentSendoutsCount: () => {},
        onRecentSendoutsCountLoaded: (state, action: PayloadAction<number>) => {
            state.recentSendoutsCount = action.payload;
        },
        getOverallStatus: () => {},
        onOverallStatusLoaded: (state, action: PayloadAction<Status>) => {
            state.overallStatus = action.payload;
        },
        markAsAborted: (state, action: PayloadAction<MarkSendoutAsAbortedActionPayload>) => {},
    },
});

export const sendoutsReducer = sendoutsSlice.reducer;

export const sendoutsActions = sendoutsSlice.actions;

export type LoadSendoutsActionPayload = {page: number};
export type CreateSendoutActionPayload = {contentId: string, title: string, campaignId: number};
export type CancelScheduledSendoutActionPayload = {sendoutId: string};
export type MarkSendoutAsAbortedActionPayload = {sendoutId: string};
export type RunSendoutActionPayload = {sendoutId: string, ignorePauseInterval: boolean, date?: Date | null};
export type DeleteSendoutActionPayload = {sendoutId: string};
export type CopySendoutActionPayload = {sendoutId: string};
export type OnSendoutCopiedActionPayload = {sendout: Sendout};
export type SendSampleActionPayload = {sendoutId: string, customEmail?: string};
