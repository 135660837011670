import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ProductDescriptor} from "../../models/product-descriptor";
import {ContentDescriptor} from "../../models/content-descriptor";
import {Page} from "../../models/page";

interface ContentState {
    products: ProductDescriptor[] | null;
    selectedProduct: ProductDescriptor | "all" | null;
    postsPage: Page<ContentDescriptor> | null;
    refreshing: boolean;
}

const initialContentState: ContentState = {
    products: null,
    selectedProduct: null,
    postsPage: null,
    refreshing: false,
};

const contentSlice = createSlice({
    name: "content",
    initialState: initialContentState,
    reducers: {
        loadProducts: () => {},
        onProductsLoaded: (state, action: PayloadAction<ProductDescriptor[]>) => {
            state.products = action.payload.sort((a, b) => {
                if (a.label > b.label) {
                    return 1;
                } else if (a.label < b.label) {
                    return -1;
                } else {
                    return 0;
                }
            });
        },
        selectProduct: (state, action: PayloadAction<ProductDescriptor | "all">) => {
            state.selectedProduct = action.payload;
            // clear loaded posts:
            state.postsPage = null;
        },
        updatePostsForSelectedProduct: (state, action: PayloadAction<UpdatePostsActionPayload>) => {},
        onPostsUpdated: (state, action: PayloadAction<Page<ContentDescriptor>>) => {
            state.postsPage = action.payload;
        },
        forceUpdateForSelectedProduct: (state) => {
            state.refreshing = true;
        },
        onForceUpdateCompleted: (state) => {
            state.refreshing = false;
        },
        deletePost: (state, action: PayloadAction<string>) => {},
    },
});

export const contentReducer = contentSlice.reducer;

export const contentActions = contentSlice.actions;

export type UpdatePostsActionPayload = {page: number};
