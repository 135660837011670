import React, {useEffect, useState} from "react";
import {SendoutProcessorsConfig} from "../../models/sendout";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {configActions} from "../../redux/slices/config-slice";
import {ToggleableProcessor} from "../ToggleableProcessor/ToggleableProcessor";
import {Modal} from "../Modal/Modal";
import Toggle from "@jetbrains/ring-ui/dist/toggle/toggle";
import {editorActions} from "../../redux/slices/editor-slice";
import {MarketoCampaign} from "../../models/marketo-config";

interface Props {
    show: boolean;
    onClose(): void;
    sendoutId: string;
    currentConfig: SendoutProcessorsConfig | null;
    campaign: MarketoCampaign;
}

export const SendoutProcessorsEditorDialog: React.FC<Props> = (props) => {

    const {show, onClose, sendoutId, currentConfig, campaign} = props;

    const dispatch = useAppDispatch();
    const processors = useAppSelector(state => state.config.knownProcessors);
    // const marketoConfig = useAppSelector(state => state.config.marketoConfig);

    const [useDefault, setUseDefault] = useState(currentConfig === null);
    const [enabledProcessors, setEnabledProcessors] = useState<string[]>([]);

    useEffect(() => {
        if (processors === null) {
            dispatch(configActions.loadKnownProcessors());
        }
    }, []);

    useEffect(() => {
        if (processors) {
            setEnabledProcessors(
                [...processors.preprocessors, ...processors.postprocessors]
                    .filter(p => currentConfig?.preprocessors?.includes(p) || currentConfig?.postprocessors?.includes(p))
            );
        }
    }, [processors]);

    // preselect all processors used for this campaign:
    useEffect(() => {
        if (!useDefault && currentConfig === null) {
            setEnabledProcessors([...campaign.preprocessors, ...campaign.postprocessors]);
        }
    }, [useDefault]);

    function toggleProcessor(processor: string) {
        if (enabledProcessors.includes(processor)) {
            setEnabledProcessors(enabledProcessors.filter(p => p !== processor));
        } else {
            setEnabledProcessors([...enabledProcessors, processor]);
        }
    }

    function save() {
        dispatch(editorActions.patchSendout({
            sendoutId,
            request: {
                processorsConfig: {
                    useDefault,
                    customValues: useDefault ? undefined : {
                        preprocessors: enabledProcessors.filter(p => p.endsWith("-preprocessor")),
                        postprocessors: enabledProcessors.filter(p => p.endsWith("-postprocessor")),
                    },
                }
            },
        }));
        onClose();
    }

    return <Modal
        title="Edit content processors"
        show={show}
        onClose={onClose}
        actions={[
            {label: "Save and regenerate content", primary: true, fn: save},
        ]}
    >
        <div>
            <Toggle checked={useDefault} onChange={() => setUseDefault(!useDefault)}>
                Use processors recommended for this Marketo campaign
            </Toggle>
        </div>
        {!useDefault && processors && <>
            <hr style={{marginTop: "20px"}}/>
            <p>Content preprocessors:</p>
            {processors.preprocessors.map(p => <ToggleableProcessor
                key={p}
                name={p}
                active={enabledProcessors.includes(p)}
                onToggle={() => toggleProcessor(p)}
            />)}
            <p>Content postprocessors:</p>
            {processors.postprocessors.map(p => <ToggleableProcessor
                key={p}
                name={p}
                active={enabledProcessors.includes(p)}
                onToggle={() => toggleProcessor(p)}
            />)}
        </>}
    </Modal>;

};
