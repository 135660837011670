import styled from "styled-components";

export const Label = styled.p`
  margin: 6px 8px 6px 0;
  color: #777;
  font-size: 0.9em;
  display: inline-block;
`;

export const CheckboxContainer = styled.div`
  padding-top: 12px;
  border-top: 1px solid #CCC;
  margin-top: 12px;
  font-size: 0.9em;
`;
