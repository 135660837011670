import React, {useState} from "react";
import * as S from "./styles";
import * as SS from "../common-styles";
import {CampaignsConfigSection} from "../CampaignsConfigSection/CampaignsConfigSection";
import {GeneralConfigSection} from "../GeneralConfigSection/GeneralConfigSection";
import Button from "@jetbrains/ring-ui/dist/button/button";
import {useAppSelector} from "../../redux/store";

enum Section {
    GENERAL,
    CAMPAIGNS,
}

export const ConfigPage: React.FC = () => {

    const newUiEnabled = useAppSelector(state => state.misc.newUi);
    const [selectedSection, setSelectedSection] = useState(Section.CAMPAIGNS);

    let content;
    switch (selectedSection) {
        case Section.CAMPAIGNS: {
            content = <CampaignsConfigSection/>;
            break;
        }
        case Section.GENERAL: {
            content = <GeneralConfigSection/>;
            break;
        }
        default: {
            content = <p>Unsupported content</p>;
            break;
        }
    }

    if (newUiEnabled) {
        return <SS.PageLayout>
            <SS.SidePanel>
                <div style={{display: "flex", flexFlow: "column nowrap", gap: "16px", padding: "0 16px"}}>
                    <Button
                        onClick={() => setSelectedSection(Section.CAMPAIGNS)}
                        primary
                    >
                        Campaigns
                    </Button>
                    <Button
                        onClick={() => setSelectedSection(Section.GENERAL)}
                        primary
                    >
                        Other
                    </Button>
                </div>
            </SS.SidePanel>
            <div style={{overflow: "auto", padding: "16px", flexGrow: 1}}>
                {content}
            </div>
        </SS.PageLayout>;
    } else {
        return <S.ConfigPageLayout>
            <S.Sidebar>
                <Button onClick={() => setSelectedSection(Section.CAMPAIGNS)} text>Campaigns</Button>
                <Button onClick={() => setSelectedSection(Section.GENERAL)} text>Other</Button>
            </S.Sidebar>
            <S.Content>
                {content}
            </S.Content>
        </S.ConfigPageLayout>;
    }

};
