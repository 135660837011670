import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Sendout} from "../../models/sendout";
import {SendoutsApi} from "../../api/sendouts-api";

interface EditorState {
    sendoutToEdit: Sendout | null;
    updatingSendout: boolean;
}

const initialEditorState: EditorState = {
    sendoutToEdit: null,
    updatingSendout: false,
};

const editorSlice = createSlice({
    name: "editor",
    initialState: initialEditorState,
    reducers: {
        clear: (state) => {
            state.sendoutToEdit = null;
        },
        loadSendout: (state, action: PayloadAction<string>) => {},
        onSendoutLoaded: (state, action: PayloadAction<Sendout>) => {
            state.sendoutToEdit = action.payload;
        },
        patchSendout: (state, action: PayloadAction<PatchSendoutActionPayload>) => {
            state.updatingSendout = true;
        },
        onSendoutPatched: (state, action: PayloadAction<Sendout>) => {
            state.updatingSendout = false;
        },
        reloadSendoutContentFromBlog: (state, action: PayloadAction<string>) => {},
    },
});

export const editorReducer = editorSlice.reducer;

export const editorActions = editorSlice.actions;

export type PatchSendoutActionPayload = {sendoutId: string, request: SendoutsApi.PatchSendoutRequest};
