import {SendoutSource} from "./sendout";
import {getDateNDaysBefore} from "../utils/utils";

export enum StatisticsEntryEventType {
    OPEN_LINK = "OPEN_LINK",                            // some tracked link was open on UI                 [payload: link url]
    LOGGED_IN = "LOGGED_IN",                            // user authentication                              [payload: -]
    SAMPLE_EMAIL = "SAMPLE_EMAIL",                      // email sample requested                           [payload: -]
    AUTOMATED_SENDOUT_RUN = "AUTOMATED_SENDOUT_RUN",    // sending/scheduling an autotriggered sendout      [payload: -]
    MANUAL_SENDOUT_RUN = "MANUAL_SENDOUT_RUN",          // sending/scheduling a manually-created sendout    [payload: -]
    MANUAL_SENDOUT_PATCH = "MANUAL_SENDOUT_PATCH",      // patching a manually-created sendout              [payload: -]
    SENDOUT_DELETED = "SENDOUT_DELETED",                // deleting a sendout                               [payload: -]
}

export interface AddStatisticsEntryRequest {
    type: StatisticsEntryEventType;
    payload?: string;
}

export class StatisticsEntry {
    public readonly timestamp: Date;
    constructor(
        public readonly userEmail: string,
        public readonly type: StatisticsEntryEventType,
        public readonly relatedSendoutId: string | null,
        public readonly relatedContentId: string | null,
        public readonly payload: string | null,
        timestamp: string | Date,
    ) {
        this.timestamp = timestamp instanceof Date ? timestamp : new Date(timestamp);
    }

    static parse(obj: any): StatisticsEntry {
        return new StatisticsEntry(
            obj.userEmail,
            obj.type,
            obj.relatedSendoutId,
            obj.relatedContentId,
            obj.payload,
            obj.timestamp,
        );
    }
}

export class OverallReport {
    public readonly timeframeSizeDays: number;
    public readonly automatedSendoutStats: SendoutStats[];
    public readonly manualSendoutStats: SendoutStats[];
    private readonly stats: OverallReportStats;

    private getStats(days: number): OverallReportStats {
        if (days === this.timeframeSizeDays) {
            return this.stats;
        } else {
            const statsCopy: any = {};
            const startDay = getDateNDaysBefore(new Date(), days);
            Object.keys(this.stats).forEach(key => {
                statsCopy[key] = this.stats[key as StatisticsEntryEventType].filter((e: StatisticsEntry) => e.timestamp >= startDay);
            });
            return statsCopy;
        }
    }

    getAutomatedSendoutsCount(timeframeDays: number, distinctByContentId: boolean): number {
        const startDate = getDateNDaysBefore(new Date(), timeframeDays);
        const stats = this.manualSendoutStats.filter(s => s.runTimestamp >= startDate);
        if (distinctByContentId) {
            return mergeStatsByContentId(stats).length;
        } else {
            return stats.length;
        }
    }

    getPercentageOfSendoutsWithSamples(days: number): number {
        const startDate = getDateNDaysBefore(new Date(), days);
        const statsForTimeframe = mergeStatsByContentId(this.manualSendoutStats.filter(s => s.runTimestamp >= startDate));
        if (statsForTimeframe.length === 0) {
            return NaN;
        }
        return Math.round(statsForTimeframe.filter(s => s.sampleEmailRequested).length / statsForTimeframe.length * 100);
    }

    getPercentageOfPatchedSendouts(days: number): number {
        const startDate = getDateNDaysBefore(new Date(), days);
        const statsForTimeframe = mergeStatsByContentId(this.manualSendoutStats.filter(s => s.runTimestamp >= startDate));
        if (statsForTimeframe.length === 0) {
            return NaN;
        }
        return Math.round(statsForTimeframe.filter(s => s.patched).length / statsForTimeframe.length * 100);
    }

    getUniqueUsers(days: number): string[] {
        const startDate = getDateNDaysBefore(new Date(), days);
        return Array.from(new Set(this.stats.LOGGED_IN.filter(e => e.timestamp >= startDate).map(e => e.userEmail)));
    }

    getLinkOpenings(days: number): Map<string, Set<string>> {
        const startDate = getDateNDaysBefore(new Date(), days);
        const result = new Map<string, Set<string>>();
        this.stats.OPEN_LINK.filter(e => e.timestamp >= startDate).forEach(entry => {
            const link = entry.payload!;
            if (!result.has(link)) {
                result.set(link, new Set<string>());
            }
            result.get(link)!.add(entry.userEmail);
        });
        return result;
    }

    // not type safe constructor:
    constructor(obj: any) {
        this.timeframeSizeDays = obj.timeframeSizeDays;
        const stats = obj.stats;
        Object.keys(stats).forEach(key => {
            stats[key] = stats[key].map((e: any) => StatisticsEntry.parse(e));
        });
        this.stats = stats;

        const mapStats = (entry: StatisticsEntry, source: SendoutSource): SendoutStats => {
            const id = entry.relatedSendoutId!;
            return {
                id,
                patched: this.stats.MANUAL_SENDOUT_PATCH.some(s => s.relatedSendoutId === id),
                sampleEmailRequested: this.stats.SAMPLE_EMAIL.some(s => s.relatedSendoutId === id),
                senderEmail: entry.userEmail,
                source,
                runTimestamp: entry.timestamp,
                contentId: entry.relatedContentId,
            };
        };
        this.automatedSendoutStats = this.stats.AUTOMATED_SENDOUT_RUN.map(e => mapStats(e, "AUTO_TRIGGERED"));
        this.manualSendoutStats = this.stats.MANUAL_SENDOUT_RUN.map(e => mapStats(e, "UI"));
    }
}

export type OverallReportStats = {
    [key in StatisticsEntryEventType]: StatisticsEntry[];
};

export interface SendoutStats {
    id: string;
    patched: boolean;
    sampleEmailRequested: boolean;
    senderEmail: string;
    source: SendoutSource;
    runTimestamp: Date;
    contentId: string | null;
}

export function mergeStatsByContentId(stats: SendoutStats[]): SendoutStats[] {
    return stats.filter(e => e.contentId).reduce((acc, curr) => {
        const existing = acc.find(e => e.contentId === curr.contentId && e.source === curr.source);
        if (!existing) {
            return [...acc, curr];
        } else {
            return [
                ...acc.filter(e => e.contentId !== curr.contentId),
                {
                    id: curr.id,
                    senderEmail: curr.senderEmail,
                    source: curr.source,
                    runTimestamp: curr.runTimestamp > existing.runTimestamp ? curr.runTimestamp : existing.runTimestamp,
                    contentId: curr.contentId,
                    patched: curr.patched || existing.patched,
                    sampleEmailRequested: curr.sampleEmailRequested || existing.sampleEmailRequested,
                } as SendoutStats,
            ];
        }
    }, [] as SendoutStats[]);
}
