import styled from "styled-components";

export const List = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 48px;
  justify-content: center;
  padding: 32px 0;
`;

export const Card = styled.div`
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  width: 400px;
  height: 360px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  background-color: whitesmoke;
  position: relative;
`;

export const DataContainer = styled.div`
  padding: 4px 16px;
  display: flex;
  flex-flow: column nowrap;
  gap: 12px;
`;

export const Image = styled.img<{grayscale?: boolean}>`
  width: 100%;
  height: 225px;
  ${props => props.grayscale ? `filter: grayscale(1);` : ""}
  transition: all ease-in-out 400ms;
  &:hover {
    ${props => props.grayscale ? `filter: grayscale(0.75);` : ""}
  }
`;

export const ProductTag = styled.label`
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: whitesmoke;
  color: black;
  font-size: 0.8em;
  padding: 8px;
  border-bottom-left-radius: 16px;
  font-weight: bold;
`;

export const Line = styled.div<{type: "sent" | "scheduled"}>`
  position: absolute;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-size: 0.9em;
  font-weight: bold;
  width: 400px;
  background-color: ${props => {
    switch (props.type) {
      case "scheduled": return "rgb(156,235,253)";
      case "sent": return "rgb(192,253,156)";
    }
  }};
  color: black;
  transform: rotate(-45deg);
  top: 40px;
  left: -150px;
`;

export const Title = styled.h2`
  margin: 0;
  font-size: 1em;
  text-align: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
`;

export const Meta = styled.p`
  margin: 0;
  color: #777;
  font-size: 0.8em;
  text-align: center;  
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

export const Button = styled.button`
    background-color: #4e9fe1;
    border: none;
    color: ghostwhite;
    height: 48px;
    line-height: 48px;
    font-size: 1em;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
    transition: background-color ease-in-out 200ms;

    &:hover {
        background-color: #0380E5;
    }
`;

export const IconButton = styled(Button)<{mainColor: string, hoverColor: string}>`
    background-color: ${props => props.mainColor};
    width: 56px;
    &:hover {
      background-color: ${props => props.hoverColor};
    }
`;
