import React, {useState} from "react";
import * as S from "./styles";
import {Modal} from "../Modal/Modal";
import ReactDatePicker from "react-datepicker";
import {getDateNDaysAfter} from "../../utils/utils";
import {MdOutlineAccessTime} from "react-icons/md";

interface Props {
    open: boolean;
    onClose(): void;
    onDateConfirmed(date: Date): void;
    alreadySent: boolean;
    alreadyScheduled: boolean;
}

const MIN_TIME = setCetTime(10, 0);
const MAX_TIME = setCetTime(19, 0);

export const ScheduleCampaignModal: React.FC<Props> = (props) => {

    const {open, onClose, onDateConfirmed, alreadySent, alreadyScheduled} = props;

    const [date, setDate] = useState<Date | null>(null);

    const badDay = date !== null && (date.getDay() < 1 || date.getDay() > 5);
    const badTime = date !== null && (date.getHours() < MIN_TIME.getHours() || date.getHours() > MAX_TIME.getHours());
    const ok = !alreadyScheduled && date !== null && !badTime && !badDay;

    function onDateChange(date: Date | null | undefined) {
        if (date?.getHours() === 0) {
            date.setHours(MIN_TIME.getHours());
        }
        setDate(date ?? null);
    }

    function schedule() {
        onDateConfirmed(date!);
    }

    return <Modal
        show={open}
        title="Schedule campaign"
        onClose={onClose}
        actions={[
            {label: `Schedule${date ? ` for ${date.toLocaleString("ru-RU", {timeZone: "CET"})}` : ""} (CET)`, disabled: !ok, fn: schedule, primary: true}
        ]}
    >
        <S.ModalContent>
            <p>Select date and time for a campaign triggering. You can cancel your sendout before this time.</p>
            <p>
                You will be notified via Slack when the campaign will be triggered.
                After triggering it also normally takes ±45min to actually start sending emails.
            </p>


            <S.DatepickerContainer>
                <ReactDatePicker
                    selected={date}
                    onChange={onDateChange}
                    minDate={new Date()}
                    maxDate={getDateNDaysAfter(new Date(), 30)}
                    dateFormat="dd.MM.yyyy HH:mm"
                    timeFormat="HH:mm"
                    showTimeSelect
                    timeIntervals={15}
                    minTime={MIN_TIME}
                    maxTime={MAX_TIME}
                    calendarStartDay={1}
                    timeCaption="Time"
                    filterDate={isWeekday}
                    showIcon
                    icon={<MdOutlineAccessTime/>}
                />
            </S.DatepickerContainer>

            {(badTime) && <S.Warning>
                Please choose time within MAU Team working hours (Monday-Friday {MIN_TIME.getHours()}:00-{MAX_TIME.getHours()}:00) so we can help you in case something went wrong
            </S.Warning>}
            {alreadySent && !alreadyScheduled && <S.Warning>
                WARNING: this content is marked as already sent!
            </S.Warning>}
            {alreadyScheduled && <S.Warning>
                There is some sendout for this content already scheduled to be sent. Please cancel it first.
            </S.Warning>}
            {ok && <p>
                When the campaign will be triggered, only <b>#email-marketing</b> team members will be able to manually stop a sendout.
            </p>}
        </S.ModalContent>
    </Modal>;

};

function isWeekday(date: Date): boolean {
    const day = date.getDay();
    return day !== 0 && day !== 6;
}

function setCetTime(hours: number, minutes: number, date: Date = new Date()): Date {
    const newDate = new Date(date);
    newDate.getTimezoneOffset()
    newDate.setUTCHours(hours - 1);
    newDate.setUTCMinutes(minutes);
    return newDate;
}