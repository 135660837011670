import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MarketoConfig} from "../../models/marketo-config";
import {AddCampaignRequest, UpdateCampaignRequest} from "../../api/config-api";

interface ConfigState {
    sendoutsBlockingEnabled: boolean | null;
    marketoConfig: MarketoConfig | null;
    knownProcessors: ProcessorsState | null;
}

interface ProcessorsState {
    preprocessors: string[];
    postprocessors: string[];
}

const initialConfigState: ConfigState = {
    sendoutsBlockingEnabled: null,
    marketoConfig: null,
    knownProcessors: null,
};

const configSlice = createSlice({
    name: "config",
    initialState: initialConfigState,
    reducers: {
        loadSendoutsBlockingFlag: () => {},
        onSendoutsBlockingFlagLoaded: (state, action: PayloadAction<boolean>) => {
            state.sendoutsBlockingEnabled = action.payload;
        },
        setSendoutsBlockingFlag: (state, action: PayloadAction<boolean>) => {},
        onSendoutsBlockingFlagUpdated: (state, action: PayloadAction<boolean>) => {
            state.sendoutsBlockingEnabled = action.payload;
        },
        loadMarketoConfig: () => {},
        onMarketoConfigLoaded: (state, action: PayloadAction<MarketoConfig>) => {
            state.marketoConfig = action.payload;
        },
        updateCampaign: (state, action: PayloadAction<UpdateCampaignActionPayload>) => {},
        onCampaignUpdated: () => {},
        addCampaign: (state, action: PayloadAction<AddCampaignActionPayload>) => {},
        onCampaignAdded: () => {},
        deleteCampaign: (state, action: PayloadAction<DeleteCampaignActionPayload>) => {},
        onCampaignDeleted: () => {},
        loadKnownProcessors: () => {},
        onKnownProcessorsLoaded: (state, action: PayloadAction<string[]>) => {
            state.knownProcessors = {
                preprocessors: action.payload.filter(p => p.endsWith("-preprocessor")),
                postprocessors: action.payload.filter(p => p.endsWith("-postprocessor")),
            };
            if (state.knownProcessors.preprocessors.length + state.knownProcessors.postprocessors.length !== action.payload.length) {
                throw Error("Error while loading available pre- and postprocessors");
            }
        },
    },
});

export const configActions = configSlice.actions;
export const configReducer = configSlice.reducer;

export type UpdateCampaignActionPayload = UpdateCampaignRequest & {campaignId: number};
export type AddCampaignActionPayload = AddCampaignRequest;
export type DeleteCampaignActionPayload = {campaignId: number};
