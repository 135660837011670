import {
    AddStatisticsEntryRequest,
    OverallReport,
    StatisticsEntry,
    StatisticsEntryEventType
} from "../models/statistics";
import {map, Observable} from "rxjs";
import axios from "axios-observable";
import {CONFIG} from "../config";

export namespace StatisticsApi {

    export function addEntry(request: AddStatisticsEntryRequest): Observable<any> {
        return axios.post(`${CONFIG.apiHost}api/statistics`, request, {withCredentials: true});
    }

    export function getEntriesByType(type: StatisticsEntryEventType): Observable<StatisticsEntry[]> {
        return axios.get(`${CONFIG.apiHost}api/statistics`, {withCredentials: true, params: {type}}).pipe(
            map(response => (response.data as any[]).map(e => StatisticsEntry.parse(e))),
        );
    }

    export function getOverallReport(): Observable<OverallReport> {
        return axios.get(`${CONFIG.apiHost}api/statistics/overall`, {withCredentials: true}).pipe(
            map(response => new OverallReport(response.data)),
        );
    }

}
