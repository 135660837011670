import styled from "styled-components";

export const ProductLabel = styled.label`
  font-size: 0.8em;
`;

export const CheckboxContainer = styled.div`
  margin-top: 12px;
  padding-top: 6px;
  border-top: 1px solid #CCC;
`;

export const Warning = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  background-color: lightgoldenrodyellow;
  font-size: 0.8em;
`;
