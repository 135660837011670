import React, {useEffect, useState} from "react";
import * as S from "./styles";
import * as SS from "../common-styles";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {usersActions} from "../../redux/slices/users-slice";
import Loader from "@jetbrains/ring-ui/dist/loader/loader";
import Badge from "@jetbrains/ring-ui/dist/badge/badge";
import Button from "@jetbrains/ring-ui/dist/button/button";
import {BsFillPersonPlusFill} from "react-icons/bs";
import {NewUserModal} from "../NewUserModal/NewUserModal";
import {EditUserModal} from "../EditUserModal/EditUserModal";
import {User, UserRole} from "../../models/user";
import Toggle from "@jetbrains/ring-ui/dist/toggle/toggle";
import Input from "@jetbrains/ring-ui/dist/input/input";
import {FaSearch} from "react-icons/fa";
import confirm from "@jetbrains/ring-ui/dist/confirm-service/confirm-service";
import {ProductDescriptor} from "../../models/product-descriptor";
import Select from "@jetbrains/ring-ui/dist/select/select";
import {Size as AvatarSize} from "@jetbrains/ring-ui/dist/avatar/avatar";
import Avatar from "@jetbrains/ring-ui/dist/avatar/avatar";
import {MdDelete} from "react-icons/md";
import {Icon} from "../Icon/Icon";
import {Size} from "@jetbrains/ring-ui/dist/input/input";

export const UsersPage: React.FC = () => {

    const {users} = useAppSelector(state => state.users);
    const products = useAppSelector(state => state.content.products);
    const newUiEnabled = useAppSelector(state => state.misc.newUi);
    const dispatch = useAppDispatch();
    const [showNewUserModal, setShowNewUserModal] = useState(false);
    const [userToEdit, setUserToEdit] = useState<User | null>(null);
    const [hideMauMembers, setHideMauMembers] = useState(false);
    const [emailFilter, setEmailFilter] = useState("");
    const [productFilter, setProductFilter] = useState<ProductDescriptor | null>(null);

    const sortedUsers = users ? [...users].sort((a, b) => {
        if (a.isMauUser && !b.isMauUser) {
            return 1;
        } else if (!a.isMauUser && b.isMauUser) {
            return -1;
        } else {
            if (a.email > b.email) {
                return 1;
            } else if (a.email < b.email) {
                return -1;
            } else {
                return 0;
            }
        }
    }) : null;

    const filteredUsers = sortedUsers
        ?.filter(u => hideMauMembers ? !u.isMauUser : true)
        .filter(u => emailFilter === "" ? true : u.email.toLowerCase().includes(emailFilter.toLowerCase()))
        .filter(u => (productFilter === null || u.authorizedProducts.includes(productFilter.label)))
        ?? null;

    useEffect(() => {
        if (users === null) {
            dispatch(usersActions.loadUsers());
        }
    }, []);

    const deleteUser = (email: string) => {
        confirm({
            text: "Delete the user?",
            description: `User ${email} will be deleted, this action can not be undone`,
        }).then(() => dispatch(usersActions.deleteUser({email})))
            .catch(() => {});
    };

    if (newUiEnabled) {
        return <SS.PageLayout>
            <SS.SidePanel style={{display: "flex", flexFlow: "column nowrap", gap: "20px", alignItems: "stretch"}}>
                <div>
                    <Select
                        selected={productFilter ? {label: productFilter.label, key: productFilter.value, value: productFilter} : null}
                        data={[
                            {label: "[Show all]", key: "all", value: null},
                            ...(products ?? []).filter(p => p.permitted).map(product => ({label: product.label, key: product.value, value: product}))
                        ]}
                        onChange={(product: any) => setProductFilter(product.value)}
                        label="Filter by product"
                    />
                </div>
                <div style={{margin: "0 auto", width: "256px"}}>
                    <Input
                        placeholder="Filter by email"
                        value={emailFilter}
                        onChange={e => setEmailFilter(e.target.value)}
                        icon={FaSearch}
                        size={Size.FULL}
                        style={{color: "white"}}
                    />
                </div>
                <Toggle defaultChecked={false} onChange={e => setHideMauMembers(e.target.checked)}>
                    Hide MAU members
                </Toggle>
                <Button
                    onClick={() => setShowNewUserModal(true)}
                    style={{margin: "20px auto 0", width: "240px"}}
                    primary={true}
                >
                    <BsFillPersonPlusFill style={{transform: "translateY(2px)"}}/>
                    &nbsp;
                    Create a new user
                </Button>
            </SS.SidePanel>
            <div style={{overflow: "auto", flexGrow: 1, padding: "48px 0", display: "flex", flexFlow: "row wrap", gap: "48px", justifyContent: "center"}}>
                {filteredUsers?.length === 0 && <S.NotFoundLabel>[No users matching your criteria]</S.NotFoundLabel>}
                {filteredUsers?.map(user => <S.Card key={user.email} style={{paddingTop: "16px"}}>
                    <div style={{textAlign: "center"}}>
                        {user.avatarUrl
                            ? <S.Avatar src={user.avatarUrl}/>
                            : <Avatar username={user.parsedName} size={AvatarSize.Size48}/>
                        }
                        <p>{user.parsedName}</p>
                    </div>
                    <S.JoinedAtLabel>Roles: <Badge>{formatRoles(user.roles)}</Badge></S.JoinedAtLabel>
                    {!user.isMauUser && <S.JoinedAtLabel>Joined: {user.createdAt.toLocaleDateString("ru-RU")}</S.JoinedAtLabel>}
                    <div>
                        <S.JoinedAtLabel style={{textAlign: "center"}}>Authorized products:</S.JoinedAtLabel>
                        {user.authorizedProducts.length > 0
                            ? user.authorizedProducts.map(product => <Badge key={product}>{product}</Badge>).slice(0, 5)
                            : <S.JoinedAtLabel style={{textAlign: "center"}}>[none]</S.JoinedAtLabel>
                        }
                        {user.authorizedProducts.length > 5 ? <Badge>[+{user.authorizedProducts.length - 5} more...]</Badge> : null}
                    </div>
                    <S.ButtonsContainer>
                        <S.Button onClick={() => setUserToEdit(user)}>
                            Edit permissions
                        </S.Button>
                        <S.IconButton onClick={() => deleteUser(user.email)} mainColor="#ff6767" hoverColor="#F64C49">
                            <Icon Type={MdDelete}/>
                        </S.IconButton>
                    </S.ButtonsContainer>

                </S.Card>) ?? <Loader message="Loading users" />}
                <NewUserModal show={showNewUserModal} onClose={() => setShowNewUserModal(false)}/>
                {userToEdit && <EditUserModal show={true} user={userToEdit} onClose={() => setUserToEdit(null)}/>}
            </div>
        </SS.PageLayout>;
    } else {
        return <div style={{padding: "8px"}}>
            <S.Controls>
                <Button onClick={() => setShowNewUserModal(true)}>
                    <BsFillPersonPlusFill style={{transform: "translateY(2px)"}}/> Create a new user
                </Button>
                <S.Filters>
                    <Toggle defaultChecked={false} onChange={e => setHideMauMembers(e.target.checked)}>
                        Hide MAU members
                    </Toggle>
                    <Select
                        selected={productFilter ? {label: productFilter.label, key: productFilter.value, value: productFilter} : null}
                        data={[
                            {label: "[Show all]", key: "all", value: null},
                            ...(products ?? []).filter(p => p.permitted).map(product => ({label: product.label, key: product.value, value: product}))
                        ]}
                        onChange={(product: any) => setProductFilter(product.value)}
                        label="Filter by product"
                    />
                    <Input placeholder="Filter by email" value={emailFilter} onChange={e => setEmailFilter(e.target.value)} icon={FaSearch}/>
                </S.Filters>
            </S.Controls>
            {filteredUsers?.length === 0 && <S.NotFoundLabel>[No users matching your criteria]</S.NotFoundLabel>}
            {filteredUsers?.map(user => <S.UserCard key={user.email}>
                <S.Header>
                    <span>{user.email}</span>
                    <Badge>{formatRoles(user.roles)}</Badge>
                </S.Header>
                {!user.isMauUser && <S.JoinedAtLabel>Joined: {user.createdAt.toLocaleDateString("ru-RU")}</S.JoinedAtLabel>}
                <S.ProductsList>
                    <p>Authorized products:</p>
                    {user.authorizedProducts.length > 0
                        ? user.authorizedProducts.map(product => <Badge key={product}>{product}</Badge>)
                        : <p>none</p>
                    }
                </S.ProductsList>
                <S.ActionsBlock>
                    <Button onClick={() => setUserToEdit(user)}>Edit user's permissions</Button>
                    <Button onClick={() => deleteUser(user.email)}>Delete user</Button>
                </S.ActionsBlock>
            </S.UserCard>) ?? <Loader message="Loading users" />}
            <NewUserModal show={showNewUserModal} onClose={() => setShowNewUserModal(false)}/>
            {userToEdit && <EditUserModal show={true} user={userToEdit} onClose={() => setUserToEdit(null)}/>}
        </div>;
    }

};

function formatRoles(roles: UserRole[]): string {
    if (roles.includes("MAU_TEAM")) {
        return "MAU Team";
    } else {
        return "Standard user";
    }
}
