import {map, Observable} from "rxjs";
import {ProductDescriptor} from "../models/product-descriptor";
import axios from "axios-observable";
import {CONFIG} from "../config";
import {ContentDescriptor} from "../models/content-descriptor";
import {ContentStatus} from "../models/content-status";
import {Page} from "../models/page";

export namespace ContentApi {

    export function getProducts(): Observable<ProductDescriptor[]> {
        console.log("Loading products");
        return axios.get(`${CONFIG.apiHost}api/content/products`, {withCredentials: true}).pipe(
            map(response => response.data as ProductDescriptor[]),
        );
    }

    export function requestProductAccess(product: ProductDescriptor): Observable<any> {
        console.log(`Requesting access to ${product.label}`);
        return axios.post(`${CONFIG.apiHost}api/content/products/${product.value}/request-access`, null, {withCredentials: true});
    }

    export function getProductPosts(product: ProductDescriptor | null, page: number, pageSize: number): Observable<Page<ContentDescriptor>> {
        console.log(`Loading posts related to ${product?.label ?? "all products"}`);
        return axios.get(`${CONFIG.apiHost}api/content/posts/${product?.value ?? ""}`, {withCredentials: true, params: {page, pageSize}}).pipe(
            map(response => {
                const page = response.data as Page<any>;
                page.content = page.content.map(e => new ContentDescriptor(
                    e.id,
                    e.title,
                    e.date,
                    e.tags,
                    e.image,
                    e.sent,
                    e.scheduled,
                    e.link,
                    e.productNames,
                    e.author,
                ));
                return page;
            }),
        );
    }

    export function forceUpdate(product: ProductDescriptor): Observable<any> {
        console.log(`Force update for ${product.label}`, {withCredentials: true});
        return axios.post(`${CONFIG.apiHost}api/content/posts/${product.value}/update`, null, {withCredentials: true}).pipe();
    }

    export function checkPostStatus(contentId: string): Observable<ContentStatus> {
        console.log(`Checking if the content with id=${contentId} was already sent`);
        return axios.get(`${CONFIG.apiHost}api/content/posts/check-status`, {withCredentials: true, params: {contentId}}).pipe(
            map(response => response.data as ContentStatus),
        );
    }

    export function deletePost(contentId: string): Observable<any> {
        console.log(`Deleting the post with contentId=${contentId}`);
        return axios.delete(`${CONFIG.apiHost}api/content/posts`, {withCredentials: true, params: {contentId}});
    }

}
