import axios from "axios-observable";
import {map, Observable} from "rxjs";
import {CONFIG} from "../config";
import {MarketoCampaign, MarketoConfig} from "../models/marketo-config";

export namespace ConfigApi {

    export function getConfig(): Observable<MarketoConfig> {
        console.log("Loading config");
        return axios.get(`${CONFIG.apiHost}api/config/`, {withCredentials: true}).pipe(
            map(response => response.data as MarketoConfig),
        );
    }

    export function getCampaign(id: number): Observable<MarketoCampaign> {
        console.log(`Loading campaign #${id}`);
        return axios.get(`${CONFIG.apiHost}api/config/campaigns/${id}`, {withCredentials: true}).pipe(
            map(response => response.data as MarketoCampaign),
        );
    }

    export function getSendoutsBlockingFlag(): Observable<boolean> {
        console.log("Loading sendouts blocking flag");
        return axios.get(`${CONFIG.apiHost}api/config/blocking`, {withCredentials: true}).pipe(
            map(response => response.data.enabled as boolean),
        );
    }

    export function setSendoutsBlockingFlag(enabled: boolean): Observable<boolean> {
        console.log(`Setting sendouts blocking flag to ${enabled ? "enabled" : "disabled"}`);
        return axios.post(`${CONFIG.apiHost}api/config/blocking`, {enabled}, {withCredentials: true}).pipe(
            map(response => response.data.enabled as boolean),
        );
    }

    export function getAvailableProcessors(): Observable<string[]> {
        console.log("Loading processors list");
        return axios.get(`${CONFIG.apiHost}api/config/processors`, {withCredentials: true}).pipe(
            map(response => response.data.processors as string[]),
        );
    }

    export function updateCampaign(campaignId: number, request: UpdateCampaignRequest): Observable<any> {
        console.log(`Updating the campaign #${campaignId}`);
        return axios.patch(`${CONFIG.apiHost}api/config/campaigns/${campaignId}`, request, {withCredentials: true});
    }

    export function addCampaign(request: AddCampaignRequest): Observable<any> {
        console.log(`Adding a new campaign for product '${request.product}'`);
        return axios.post(`${CONFIG.apiHost}api/config/campaigns`, request, {withCredentials: true});
    }

    export function deleteCampaign(campaignId: number): Observable<any> {
        console.log(`Deleting campaign config for campaign #${campaignId}`);
        return axios.delete(`${CONFIG.apiHost}api/config/campaigns/${campaignId}`, {withCredentials: true});
    }

}

export interface UpdateCampaignRequest {
    newCampaignId?: number | null;
    programId: number;
    name: string;
    emailId: number;
    description: string;
    preprocessors: string[];
    postprocessors: string[];
    internal: boolean;
    additionalCss: string | null;
    suppressNotification: boolean;
}

export interface AddCampaignRequest {
    product: string;
    campaignId: number;
    name: string;
    programId: number;
    emailId: number;
    description: string;
    preprocessors: string[];
    postprocessors: string[];
    internal: boolean;
    additionalCss: string | null;
}
