import styled from "styled-components";

export const ProductsList = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 4px 2px;
  font-size: 0.8em;
  white-space: nowrap;
`;

export const Label = styled.label`
  display: block;
  color: #777;
  margin: 8px 0 4px;
`;
