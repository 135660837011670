import styled from "styled-components";

export const Title = styled.h3`
  margin: 0;
`;

export const List = styled.ul`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
`;

export const ListItem = styled.li`
  width: 360px;
  padding: 16px;
  border-radius: 16px;
  border: 1px solid #DDD;
  list-style-type: none;
  font-size: 0.8em;
  text-align: center;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const CampaignTitle = styled.h4`
  margin: 0;
  font-size: 1.1em;
`;

export const CampaignDescription = styled.p`
  margin: 0;
  color: #777;
`;

export const CampaignNumberLabel = styled.p`
  margin: 0;
  color: #444;
`;

export const ButtonsGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 4px;
`;

export const InternalTag = styled.span`
  margin-left: 12px;
  font-size: 0.8em;
  background-color: #F48C8C;
  padding: 4px 8px;
  border-radius: 4px;
`;
