import {combineEpics, Epic, ofType, StateObservable} from "redux-observable";
import {contentActions, UpdatePostsActionPayload} from "../slices/content-slice";
import {catchError, map, of, switchMap, tap} from "rxjs";
import {ContentApi} from "../../api/content-api";
import {RootState} from "../store";
import {PayloadAction} from "@reduxjs/toolkit";
import {ProductDescriptor} from "../../models/product-descriptor";
import {miscActions} from "../slices/misc-slice";
import alertService from "@jetbrains/ring-ui/dist/alert-service/alert-service";
import {CONFIG} from "../../config";
import {getRecommendedPageSize} from "../../utils/utils";

const onInit$: Epic = (action$) => action$.pipe(
    ofType(miscActions.onInit),
    map(contentActions.loadProducts),
);

const loadProducts$: Epic = (action$) => action$.pipe(
    ofType(contentActions.loadProducts),
    switchMap(() => ContentApi.getProducts().pipe(
        map(products => contentActions.onProductsLoaded(products)),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onProductSelected$: Epic = (action$) => action$.pipe(
    ofType(contentActions.selectProduct),
    map((action: PayloadAction<ProductDescriptor>) => contentActions.updatePostsForSelectedProduct({page: 0})),
);

const updatePostsForSelectedProduct$: Epic = (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType(contentActions.updatePostsForSelectedProduct),
    switchMap((action: PayloadAction<UpdatePostsActionPayload>) => ContentApi
        .getProductPosts(
            state$.value.content.selectedProduct === "all" ? null : state$.value.content.selectedProduct!,
            action.payload.page,
            state$.value.misc.newUi ? getRecommendedPageSize() : 10,
        ).pipe(
            map(posts => contentActions.onPostsUpdated(posts)),
            catchError((response) => of(miscActions.onError(response))),
        )
    ),
);

const forceUpdateForCurrentProduct$: Epic = (action$, state$: StateObservable<RootState>) => action$.pipe(
    ofType(contentActions.forceUpdateForSelectedProduct),
    switchMap(() => ContentApi.forceUpdate(state$.value.content.selectedProduct as ProductDescriptor).pipe(
        map(() => contentActions.onForceUpdateCompleted()),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

const onForceUpdateCompleted$: Epic = (action$) => action$.pipe(
    ofType(contentActions.onForceUpdateCompleted),
    map(() => contentActions.updatePostsForSelectedProduct({page: 0})),
    tap(() => {
        alertService.successMessage("Content update completed", CONFIG.defaultAlertsTimeoutMs);
    }),
);

const deletePost$: Epic = (action$) => action$.pipe(
    ofType(contentActions.deletePost),
    switchMap((action: PayloadAction<string>) => ContentApi.deletePost(action.payload).pipe(
        map(() => contentActions.updatePostsForSelectedProduct({page: 0})),
        catchError((response) => of(miscActions.onError(response))),
    )),
);

export const contentEpics = combineEpics(
    onInit$,
    loadProducts$,
    onProductSelected$,
    updatePostsForSelectedProduct$,
    forceUpdateForCurrentProduct$,
    onForceUpdateCompleted$,
    deletePost$,
);
