import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {OverallReport} from "../../models/statistics";
import {StatisticsApi} from "../../api/statistics-api";
import ButtonGroup from "@jetbrains/ring-ui/dist/button-group/button-group";
import Button from "@jetbrains/ring-ui/dist/button/button";
import Tooltip from "@jetbrains/ring-ui/dist/tooltip/tooltip";

const Metric: React.FC<{value: number | string, label: string, percentage?: boolean, children?: React.ReactNode}> = (props) => {

    const {value, label, percentage, children} = props;
    const nan = typeof value === "number" && isNaN(value);

    return <S.MetricContainer hover={!!children}>
        <S.MetricValue>{nan ? "N/A" : value}{!nan && percentage && "%"}</S.MetricValue>
        <S.MetricLabel>{label}</S.MetricLabel>
        {children && <S.HoverContainer>{children}</S.HoverContainer>}
    </S.MetricContainer>;

};

export const StatisticsPage: React.FC = () => {

    const [report, setReport] = useState<null | OverallReport>(null);
    const [timeframeDays, setTimeframeDays] = useState(30);

    useEffect(() => refreshStats(), []);

    const uniqueUsers = report?.getUniqueUsers(timeframeDays) ?? null;
    const linkOpenings = report?.getLinkOpenings(timeframeDays) ?? null;

    function refreshStats() {
        StatisticsApi.getOverallReport().subscribe(setReport);
    }

    if (report === null) {
        return <p>Loading statistics...</p>;
    }

    return <S.CategoriesList>
        <S.ControlsContainer>
            <span>Timeframe:</span>
            <ButtonGroup>
                <Button onClick={() => setTimeframeDays(1)} active={timeframeDays === 1}>1 day</Button>
                <Button onClick={() => setTimeframeDays(7)} active={timeframeDays === 7}>7 days</Button>
                <Button onClick={() => setTimeframeDays(14)} active={timeframeDays === 14}>14 days</Button>
                <Button onClick={() => setTimeframeDays(30)} active={timeframeDays === 30}>30 days</Button>
            </ButtonGroup>
        </S.ControlsContainer>
        <S.MetricsList>
            <Metric value={uniqueUsers!.length} label="Unique users">
                {uniqueUsers!.map(user => <p key={user}>{user}</p>)}
            </Metric>
            <Metric value={report.getAutomatedSendoutsCount(timeframeDays, false)} label="Total sendouts"/>
            <Metric value={report.getAutomatedSendoutsCount(timeframeDays, true)} label="Total sendouts (unique)"/>
            <Metric value={report.getPercentageOfPatchedSendouts(timeframeDays)} label="Patched sendouts percentage" percentage/>
            <Metric value={report.getPercentageOfSendoutsWithSamples(timeframeDays)} label="Sendouts tested with email sample(s)" percentage/>
            <S.LinkOpeningsContainer>
                <p>Link openings:</p>
                {linkOpenings && linkOpenings.size > 0
                    && <div>
                        {Array.from(linkOpenings.keys()).map(key =>
                            <p key={key}>
                                <Tooltip title={Array.from(linkOpenings.get(key)!).join(", ")}>
                                    <S.VisitsCount>{linkOpenings.get(key)!.size}</S.VisitsCount>
                                </Tooltip>
                                &nbsp;– <a href={key} target="_blank" rel="noreferrer">{key}</a>
                            </p>
                        )}
                    </div>
                    || <p>(No data)</p>
                }
            </S.LinkOpeningsContainer>
        </S.MetricsList>
    </S.CategoriesList>;

};
