import styled from "styled-components";

export const DialogContent = styled.div`
  padding: 16px;
  h2 {
    margin: 0 0 12px;
  }
`;

export const DialogControls = styled.div`
  padding: 0 16px 16px;
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
  &::before {
    content: "";
    flex-grow: 1;
  }
`;

export const PageLayout = styled.div`
  display: flex;
  flex-flow: row nowrap;
    height: 100%;
    width: 100vw;
`;

export const SidePanel = styled.div`
  width: 300px;
  padding: 20px 8px 8px;
  background-color: #222;
  color: whitesmoke;
  text-align: center;
  flex-shrink: 0;
`;
