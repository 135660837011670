import React from "react";
import * as S from "./styles";
import {StatisticsService} from "../../utils/statistics-service";

export const Footer: React.FC = () => {

    function openReleaseNotes() {
        StatisticsService.openLinkAndAddEntry("https://youtrack.jetbrains.com/articles/MAU-A-10/Release-Notes-December-23");
    }

    function openDocs() {
        StatisticsService.openLinkAndAddEntry("https://youtrack.jetbrains.com/articles/MKT-A-236159877");
    }

    return <S.StyledFooter>
        <S.Link href="javascript:void(0)" onClick={openReleaseNotes}>🚀 Release notes</S.Link>
        <S.Link href="https://jetstat.aws.intellij.net/dashboard/610" target="_blank">E-mail audience</S.Link>
        <S.Link href="javascript:void(0)" onClick={openDocs}>User manual</S.Link>
        <S.Link href="https://jetdigest.labs.jb.gg" target="_blank">JetDigest</S.Link>
        <S.Link href="https://jetbrains.slack.com/app_redirect?channel=email-marketing" target="_blank">Contact Us</S.Link>
    </S.StyledFooter>;

};
