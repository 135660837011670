import React, {useEffect, useState} from "react";
import * as S from "./styles";
import {Modal} from "../Modal/Modal";
import Input from "@jetbrains/ring-ui/dist/input/input";
import {Size} from "@jetbrains/ring-ui/dist/input/input";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import {configActions} from "../../redux/slices/config-slice";
import {subscribeActionAfter} from "redux-subscribe-action";
import {ToggleableProcessor} from "../ToggleableProcessor/ToggleableProcessor";
import Checkbox from "@jetbrains/ring-ui/dist/checkbox/checkbox";
import {validateField} from "../../utils/utils";

interface Props {
    open: boolean;
    product: string;
    onClose(): void;
}

const ID_REGEX = /^[1-9]\d+$/;

const processorsEnabledByDefault = [
    "buttons-preprocessor",
    "convert-to-summary-preprocessor",
    "inline-code-postprocessor",
    "marketo-symbols-postprocessor",
    "digest-postprocessor",
];

export const NewCampaignConfigModal: React.FC<Props> = (props) => {

    const {open, product, onClose} = props;
    const processors = useAppSelector(state => state.config.knownProcessors);
    const showDebugFields = useAppSelector(state => state.users.currentUserInfo?.isDeveloper ?? false);
    const dispatch = useAppDispatch();

    const [campaignId, setCampaignId] = useState("");
    const [programId, setProgramId] = useState("");
    const [name, setName] = useState("");
    const [emailId, setEmailId] = useState("");
    const [description, setDescription] = useState("");
    const [selectedPreprocessors, setSelectedPreprocessors] = useState<string[]>([]);
    const [selectedPostprocessors, setSelectedPostprocessors] = useState<string[]>([]);
    const [internal, setInternal] = useState(false);
    const [additionalCss, setAdditionalCss] = useState("");

    const formIsValid = processors !== null && ID_REGEX.test(campaignId) && ID_REGEX.test(programId) &&
        ID_REGEX.test(emailId) && description.trim() !== "" && name.trim() !== "";

    useEffect(() => {
        subscribeActionAfter(configActions.onCampaignAdded.type, () => onClose());
        if (processors === null) {
            dispatch(configActions.loadKnownProcessors());
        }
    }, []);

    useEffect(() => {
        if (processors !== null && selectedPreprocessors.length === 0 && selectedPostprocessors.length === 0) {
            setSelectedPreprocessors(processors.preprocessors.filter(p => processorsEnabledByDefault.includes(p)));
            setSelectedPostprocessors(processors.postprocessors.filter(p => processorsEnabledByDefault.includes(p)));
        }
    }, [processors]);

    function togglePreprocessor(preprocessor: string) {
        if (selectedPreprocessors.includes(preprocessor)) {
            setSelectedPreprocessors(selectedPreprocessors.filter(p => p !== preprocessor));
        } else {
            setSelectedPreprocessors([...selectedPreprocessors, preprocessor]);
        }
    }

    function togglePostprocessor(postprocessor: string) {
        if (selectedPostprocessors.includes(postprocessor)) {
            setSelectedPostprocessors(selectedPostprocessors.filter(p => p !== postprocessor));
        } else {
            setSelectedPostprocessors([...selectedPostprocessors, postprocessor]);
        }
    }

    function save() {
        dispatch(configActions.addCampaign({
            product,
            campaignId: Number(campaignId),
            programId: Number(programId),
            emailId: Number(emailId),
            preprocessors: selectedPreprocessors,
            postprocessors: selectedPostprocessors,
            description,
            internal,
            name,
            additionalCss: additionalCss.trim() === "" ? null : additionalCss,
        }));
    }

    return <Modal
        show={open}
        title={`New campaign for ${product}`}
        onClose={onClose}
        actions={[{
            label: "Save",
            fn: save,
            primary: true,
            disabled: !formIsValid,
        }]}
    >
        <Input
            value={name}
            label="Name"
            onChange={e => setName(e.target.value)}
            size={Size.FULL}
            error={validateField(name, {nonEmpty: true})}
        />
        <Input
            value={description}
            label="Description"
            onChange={e => setDescription(e.target.value)}
            size={Size.FULL}
        />
        <Input
            value={campaignId}
            label="Campaign ID"
            onChange={e => setCampaignId(e.target.value)}
            size={Size.FULL}
            error={validateField(campaignId, {nonEmpty: true, positiveNumber: true})}
        />
        <Input
            value={programId}
            label="Program ID"
            onChange={e => setProgramId(e.target.value)}
            size={Size.FULL}
            error={validateField(programId, {nonEmpty: true, positiveNumber: true})}
        />
        <Input
            value={emailId}
            label="Email ID"
            onChange={e => setEmailId(e.target.value)}
            size={Size.FULL}
            error={validateField(emailId, {nonEmpty: true, positiveNumber: true})}
        />
        {showDebugFields && <Input
            value={additionalCss}
            label="Additional CSS path (optional)"
            onChange={e => setAdditionalCss(e.target.value)}
            size={Size.FULL}
        />}
        {processors && <>
            <div>
                <S.Label>Preprocessors:</S.Label>
                {processors.preprocessors.map(p => {
                    const selected = selectedPreprocessors.includes(p);
                    return <ToggleableProcessor key={p} name={p} active={selected} onToggle={() => togglePreprocessor(p)}/>;
                })}
            </div>
            <div>
                <S.Label>Postprocessors:</S.Label>
                {processors.postprocessors.map(p => {
                    const selected = selectedPostprocessors.includes(p);
                    return <ToggleableProcessor key={p} name={p} active={selected} onToggle={() => togglePostprocessor(p)}/>;
                })}
            </div>
        </>}
        <S.CheckboxContainer>
            <Checkbox
                label="Available only for MAU users"
                checked={internal}
                onChange={() => setInternal(!internal)}
            />
        </S.CheckboxContainer>
    </Modal>;

};
