import {createSlice, PayloadAction} from "@reduxjs/toolkit";

const OLD_UI_LOCAL_STORAGE_KEY = "jetblog-old-ui";

interface MiscState {
    newUi: boolean;
}

const initialMiscState: MiscState = {
    newUi: localStorage.getItem(OLD_UI_LOCAL_STORAGE_KEY) !== "true",
};

const miscSlice = createSlice({
    name: "misc",
    initialState: initialMiscState,
    reducers: {
        onInit: () => {},
        onError: (state, action: PayloadAction<any>) => {},
        setNewUiState: (state, action: PayloadAction<boolean>) => {
            const enabled = action.payload;
            if (enabled) {
                localStorage.removeItem(OLD_UI_LOCAL_STORAGE_KEY);
            } else {
                localStorage.setItem(OLD_UI_LOCAL_STORAGE_KEY, "true");
            }
            state.newUi = enabled;
        },
    },
});

export const miscActions = miscSlice.actions;
export const miscReducer = miscSlice.reducer;
