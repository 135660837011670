import styled from "styled-components";

export const ActionsBlock = styled.div`
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-in-out;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  &::before {
    flex-grow: 1;
    content: "";
  }
`;

export const UserCard = styled.div`
  margin: 10px 0;
  padding: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  
  &:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    ${ActionsBlock} {
      height: 28px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

export const ProductsList = styled.div`
  font-size: 0.8em;
  color: #888;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  align-items: center;
  margin-bottom: 6px;
`;

export const JoinedAtLabel = styled.p`
  margin: 0;
  color: gray;
  font-size: 0.8em;
`;

export const Controls = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
`;

export const Filters = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 12px;
  align-items: center;
  svg {
    transform: translateY(2px);
  }
`;

export const NotFoundLabel = styled.p`
  text-align: center;
  margin-top: 10vh;
  color: #AAA;
  font-style: italic;
`;

export const Card = styled.div`
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  width: 400px;
  height: 300px;
  border-radius: 16px;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  background-color: whitesmoke;
  position: relative;
    align-items: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
`;

export const Button = styled.button`
    background-color: #4e9fe1;
    border: none;
    color: ghostwhite;
    height: 48px;
    line-height: 48px;
    font-size: 1em;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
    transition: background-color ease-in-out 200ms;

    &:hover {
        background-color: #0380E5;
    }
`;

export const IconButton = styled(Button)<{mainColor: string, hoverColor: string}>`
    background-color: ${props => props.mainColor};
    width: 56px;
    &:hover {
      background-color: ${props => props.hoverColor};
    }
`;

export const Avatar = styled.img`
  height: 48px;
  border-radius: 4px;
`;
