import styled from "styled-components";
import {Link} from "react-router-dom";
import Button from "@jetbrains/ring-ui/dist/button/button";

export const HeaderContent = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  svg {
    transform: translateY(2px);
  }
  h1 {
    margin: 0 20px 0 0;
    font-size: 22px;
    color: white;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
`;

export const LinksBlock = styled.div`
  flex-grow: 1;
  display: flex;
  flex-flow: row nowrap;
  gap: 20px;
  margin: 0 20px;
`;

export const HeaderLink = styled(Link)`
  color: whitesmoke;
  text-decoration: none;
  display: block;
  padding: 0 20px;
  position: relative;
  &.selected::after {
    height: 2px;
  }
  &::after {
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 0;
    background-color: #f50057;
    content: '';
    transition: height 300ms ease-in-out;
  }
  &:hover::after {
    height: 4px;
  }
`;

export const HeaderButton = styled(Button)`
  svg {
    color: white;
  }
`;

export const Logo = styled.img`
  height: 48px;
  border-right: 1px solid #333;
`;

export const UserInfoBlock = styled.div`
  height: 40px;
  line-height: 40px;
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
`;

export const UserAvatar = styled.img<{rounded: boolean}>`
  height: 100%;
  border-radius: ${props => props.rounded ? "50%" : "4px"};
`;
