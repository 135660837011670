export class User {
    readonly createdAt: Date;
    constructor(
        public readonly email: string,
        public readonly authorizedProducts: string[],
        createdAt: string,
        public readonly roles: UserRole[],
        public readonly avatarUrl: string | null,
    ) {
        this.createdAt = new Date(createdAt);
    }

    get isMauUser() {
        return this.roles.includes("MAU_TEAM");
    }

    get featureRoles(): FeatureRole[] {
        return this.roles.filter(r => r !== "REGULAR_USER") as FeatureRole[];
    }

    get parsedName(): string {
        const [namePart] = this.email.split("@");
        let parts = namePart.split(".");
        parts = parts.map(part => part.charAt(0).toUpperCase() + part.slice(1));
        return parts.join(" ");
    }

    static parse(obj: any): User {
        return new User(
            obj.email,
            obj.authorizedProducts,
            obj.createdAt,
            obj.roles ?? [],
            obj.avatarUrl ?? null,
        );
    }
}

export type UserRole = "REGULAR_USER" | "MAU_TEAM" | "ADVANCED_EDITOR" | "PROCESSORS_EDITOR" | "FEATURE_TESTER";
export type FeatureRole = Exclude<UserRole, "REGULAR_USER">;
